<template>
  <button
    v-if="products"
    id="shop"
    class="section article__marquee"
    @mouseover="isPaused = true"
    @mouseleave="isPaused = false"
    @click="
      openQuickShopModal({
        activeProductUid: products[0]._meta.uid,
        type: 'article',
      })
    "
  >
    <h2 class="accessible">Shop The Story</h2>

    <ClientOnly>
      <component
        :is="numProducts > 1 ? 'marquee-text' : 'div'"
        :duration="numProducts * 10"
        :paused="isPaused"
      >
        <MarqueeItem
          v-for="product in products"
          :product="product"
          :key="product.uid"
        />
      </component>
    </ClientOnly>
  </button>
</template>

<script>
  import { mapActions } from 'vuex'

  import MarqueeItem from '@/components/pages/article/MarqueeItem'

  export default {
    name: 'Marquee',

    components: {
      MarqueeItem,
    },

    data() {
      return {
        isPaused: false,
      }
    },

    props: {
      products: {
        type: Array,
        default: null,
      },
    },

    computed: {
      numProducts() {
        if (this.products) {
          return this.products.length
        }
      },
    },

    methods: {
      ...mapActions(['openQuickShopModal']),
    },
  }
</script>

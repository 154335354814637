<template>
  <PageTitle
    :title="title ? title : 'Beauty <span>Edit</span>'"
    :text="description"
  >
    <Tooltip v-if="categories" :isArrowDropdown="true">
      <template v-slot:trigger>
        {{ currentCategoryTitle }}
      </template>
      <template v-slot:content>
        <ul>
          <li>
            <nuxt-link :to="buildLink('category')">All Categories</nuxt-link>
          </li>
          <li v-for="category in categories" :key="category._meta.uid">
            <nuxt-link :to="buildLink('category', category._meta.uid)">
              {{ category.title | getTitle }}
            </nuxt-link>
          </li>
        </ul>
      </template>
    </Tooltip>
    <template v-if="authors">
      <span class="display">by</span>
      <Tooltip :isArrowDropdown="true">
        <template v-slot:trigger>
          {{ currentAuthorTitle }}
        </template>
        <template v-slot:content>
          <ul>
            <li>
              <nuxt-link :to="buildLink('author')">All Authors</nuxt-link>
            </li>
            <li v-for="author in authors" :key="author._meta.uid">
              <nuxt-link
                :to="buildLink('author', author._meta.uid)"
                class="has-image"
              >
                <PrismicLazyImage
                  :image="author.featured_image"
                  width="32"
                  height="32"
                  class="cover"
                />
                {{ author.name | getTitle }}
              </nuxt-link>
            </li>
          </ul>
        </template>
      </Tooltip>
    </template>
  </PageTitle>
</template>

<script>
  import { getTitle } from '@/helpers'

  import PageTitle from '@/components/global/PageTitle'
  import Tooltip from '@/components/global/Tooltip'
  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage'

  export default {
    name: 'Top',

    components: {
      PageTitle,
      Tooltip,
      PrismicLazyImage,
    },

    props: {
      title: {
        type: String,
        default: null,
      },
      description: {
        type: String,
        default: null,
      },
      categories: {
        type: Array,
        default: null,
      },
      authors: {
        type: Array,
        default: null,
      },
      currentCategory: {
        type: Object,
        default: null,
      },
      currentAuthor: {
        type: Object,
        default: null,
      },
    },

    computed: {
      currentCategoryTitle() {
        return this.currentCategory
          ? getTitle(this.currentCategory.title)
          : 'All Categories'
      },
      currentAuthorTitle() {
        return this.currentAuthor
          ? getTitle(this.currentAuthor.name)
          : 'All Authors'
      },
      currentCategoryUid() {
        if (!this.currentCategory) return null

        return this.currentCategory._meta.uid
      },
      currentAuthorUid() {
        if (!this.currentAuthor) return null

        return this.currentAuthor._meta.uid
      },
    },

    methods: {
      buildLink(type, value) {
        const typeAndValue = value ? `${type}/${value}` : ''

        if (type !== 'author') {
          const authorUid = this.currentAuthorUid
            ? `author/${this.currentAuthorUid}`
            : ''

          if (!typeAndValue.length) return `/beauty-edit/${authorUid}`

          return `/beauty-edit/${typeAndValue}/${authorUid}`
        }

        const categoryUid = this.currentCategoryUid
          ? `category/${this.currentCategoryUid}`
          : ''

        return `/beauty-edit/${categoryUid}/${typeAndValue}`
      },
    },
  }
</script>

<template>
  <Section :isVisible="isVisible" type="intro">
    <template v-slot:header>
      <h2>Afternoon, <span>Gorgeous</span></h2>
      <p>How this all works:</p>
    </template>
    <template v-slot:content>
      <div>
        <span><small>1</small></span>
        <p>You fill out this short but informative quiz.</p>
      </div>
      <div>
        <span><small>2</small></span>
        <p>
          We <span>personally</span> (<span class="bold">Yes us</span>)
          review your answers and suggest a custom regimen based on your
          answers.
        </p>
      </div>
      <div>
        <span><small>3</small></span>
        <p>Get your new, personalized regimen right to your door.</p>
      </div>
    </template>
    <template v-slot:buttons>
      <Button
        text="Let's Get Glowing"
        isFakeButton
        @click="$emit('click')"
      />
      <ArrowLink to="/shop" text="No Thanks, Let me Shop" />
    </template>
  </Section>
</template>

<script>
  import Section from '@/components/pages/quiz/Section';
  import Button from '@/components/global/Button';
  import ArrowLink from '@/components/global/ArrowLink';

  export default {
    name: 'Intro',

    components: {
      Section,
      Button,
      ArrowLink
    },

    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<template>
  <div class="row">
    <div class="column small-12 xxlarge-10 xxxlarge-8">
      <form
        class="form"
        :class="{ 'has-errors': $v.$anyError }"
        @submit.prevent="handleSubmit"
      >
        <div class="form__row">
          <div
            class="form__field form__field--half"
            :class="{ 'has-error': $v.first_name.$error }"
          >
            <label for="first_name">First Name</label>
            <input
              id="first_name"
              name="first_name"
              type="text"
              v-model.trim="first_name"
            />
            <span
              class="error"
              v-if="$v.first_name.$error && !$v.first_name.required"
            >
              This field is required
            </span>
          </div>

          <div
            class="form__field form__field--half"
            :class="{ 'has-error': $v.last_name.$error }"
          >
            <label for="last_name">Last Name</label>
            <input
              id="last_name"
              name="last_name"
              type="text"
              v-model.trim="last_name"
            />
            <span
              class="error"
              v-if="$v.last_name.$error && !$v.last_name.required"
            >
              This field is required
            </span>
          </div>
        </div>

        <div class="form__row">
          <div
            class="form__field"
            :class="{ 'has-error': $v.address_1.$error }"
          >
            <label for="address_1">Address</label>
            <input
              id="address_1"
              name="address_1"
              type="text"
              v-model.trim="address_1"
            />
            <span
              class="error"
              v-if="$v.address_1.$error && !$v.address_1.required"
            >
              This field is required
            </span>
          </div>
        </div>

        <div class="form__row">
          <div class="form__field">
            <label for="address_2">Address Line 2 (Optional)</label>
            <input
              id="address_2"
              name="address_2"
              type="text"
              v-model.trim="address_2"
            />
          </div>
        </div>

        <div class="form__row">
          <div class="form__field" :class="{ 'has-error': $v.city.$error }">
            <label for="city">City</label>
            <input id="city" name="city" type="text" v-model.trim="city" />
            <span class="error" v-if="$v.city.$error && !$v.city.required">
              This field is required
            </span>
          </div>
        </div>

        <div class="form__row form__row--margin-top">
          <div
            class="form__field form__field--half"
            :class="{ 'has-error': $v.region.$error }"
          >
            <label class="accessible" for="region">
              {{ this.locale === 'en-ca' ? 'Province' : 'State' }}
            </label>
            <div class="select">
              <regionSelect
                id="region"
                name="region"
                v-model="region"
                :country="country"
                :region="region"
                :placeholder="this.locale === 'en-ca' ? 'Province' : 'State'"
                :defaultRegion="this.locale === 'en-ca' ? 'CA' : 'US'"
                :autocomplete="true"
              />
            </div>
            <span class="error" v-if="$v.region.$error && !$v.region.required">
              This field is required
            </span>
          </div>

          <div
            class="form__field form__field--half"
            :class="{ 'has-error': $v.country.$error }"
          >
            <label class="accessible" for="country">Country</label>
            <div class="select">
              <countrySelect
                id="country"
                name="country"
                v-model="country"
                :country="country"
                placeholder="Country"
                :topCountry="this.locale === 'en-ca' ? 'CA' : 'US'"
                :autocomplete="true"
              />
            </div>
            <span
              class="error"
              v-if="$v.country.$error && !$v.country.required"
            >
              This field is required
            </span>
          </div>
        </div>

        <div class="form__row">
          <div
            class="form__field form__field--half"
            :class="{ 'has-error': $v.zip_postal.$error }"
          >
            <label for="zip_postal">Postal / Zipcode</label>
            <input
              id="zip_postal"
              name="zip_postal"
              type="text"
              v-model.trim="zip_postal"
            />
            <span
              class="error"
              v-if="$v.zip_postal.$error && !$v.zip_postal.required"
            >
              This field is required
            </span>
          </div>
          <div
            class="form__field form__field--half"
            :class="{ 'has-error': $v.phone.$error }"
          >
            <label for="phone">Phone Number</label>
            <input id="phone" name="phone" type="text" v-model.trim="phone" />
            <span class="error" v-if="$v.phone.$error && !$v.phone.required">
              This field is required
            </span>
          </div>
        </div>

        <div class="form__row">
          <div class="form__field">
            <input
              id="primary_address"
              type="checkbox"
              name="primary_address"
              v-model="primary_address"
              class="yes-no"
            />
            <label for="primary_address">Set As Primary Address?</label>
          </div>
        </div>

        <div class="form__row form__row--button">
          <div class="form__field">
            <Button type="submit" :text="buttonText" :disabled="isSubmitting" />
            <FormFeedback
              :showFeedback="showFeedback"
              :submitState="submitState"
              :submitMessage="submitMessage"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { required, requiredIf } from 'vuelidate/lib/validators'

  import formMixin from '@/mixins/formMixin'

  import {
    createNewAddress,
    getCustomerAddressess,
    updateAddress,
    updateDefaultAddress,
  } from '@/shopify/customer'

  import Button from '@/components/global/Button'
  import FormFeedback from '@/components/global/FormFeedback'

  export default {
    name: 'AddressForm',

    mixins: [formMixin],

    components: {
      Button,
      FormFeedback,
    },

    data() {
      return {
        first_name: null,
        last_name: null,
        address_1: null,
        address_2: null,
        city: null,
        region: null,
        country: null,
        zip_postal: null,
        phone: null,
        primary_address: false,
        defaultButtonText:
          'Save ' + (this.type == 'edit' ? 'Changes' : 'Address'),
      }
    },

    props: {
      type: {
        type: String,
        default: null,
      },
      accessToken: {
        type: String,
        default: null,
      },
      addressId: {
        type: String,
        default: null,
      },
    },

    validations: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      address_1: {
        required,
      },
      city: {
        required,
      },
      region: {
        required,
      },
      country: {
        required,
      },
      zip_postal: {
        required: requiredIf(vm => {
          return vm.country == 'US' || vm.country == 'CA'
        }),
      },
      phone: {
        required,
      },
    },

    computed: {
      ...mapState({
        locale: state => state.locale,
      }),
    },

    methods: {
      parseAddressInfo(address) {
        this.id = address.id
        this.first_name = address.firstName
        this.last_name = address.lastName
        this.address_1 = address.address1
        this.address_2 = address.address2
        this.city = address.city
        this.region = address.provinceCode
        this.country = address.countryCodeV2
        this.zip_postal = address.zip
        this.phone = address.phone
      },

      findAddressToEdit(address) {
        const paramId = this.parseAddressID(this.addressId)
        const nodeId = this.parseAddressID(address.node.id)

        return nodeId === paramId
      },

      parseAddressID(value) {
        return atob(value)
          .split('?')
          .shift()
      },

      async submitNewAddress(address) {
        try {
          const { data } = await this.$shopify.client.mutate({
            mutation: createNewAddress,
            variables: {
              customerAccessToken: this.accessToken,
              address: address,
            },
          })

          const { customerAddressCreate } = data

          if (customerAddressCreate.customerUserErrors.length) {
            const errorMessage =
              customerAddressCreate.customerUserErrors[0].message

            this.handleFormFeedback('error', errorMessage)
            throw new Error(errorMessage)
          }

          this.handleFormFeedback('success', 'Your address has been added!')

          setTimeout(() => {
            this.$router.push({ name: 'addresses' })
          }, 1000)
        } catch (err) {
          console.error(err)
          this.handleFormFeedback(
            'error',
            'An error occured trying to add your address'
          )
        }
      },

      async submitUpdatedAddress(address) {
        try {
          const { data } = await this.$shopify.client.mutate({
            mutation: updateAddress,
            variables: {
              customerAccessToken: this.accessToken,
              id: this.id,
              address: address,
            },
          })

          const { customerAddressUpdate } = data

          if (customerAddressUpdate.customerUserErrors.length) {
            const errorMessage =
              customerAddressUpdate.customerUserErrors[0].message

            this.handleFormFeedback('error', errorMessage)
            throw new Error(errorMessage)
          }

          if (this.primary_address) {
            const { data } = await this.$shopify.client.mutate({
              mutation: updateDefaultAddress,
              variables: {
                customerAccessToken: this.accessToken,
                addressId: this.id,
              },
            })

            const { customerDefaultAddressUpdate } = data

            if (customerDefaultAddressUpdate.customerUserErrors.length) {
              const errorMessage =
                customerDefaultAddressUpdate.customerUserErrors[0].message

              this.handleFormFeedback('error', errorMessage)
              throw new Error(errorMessage)
            }
          }

          this.handleFormFeedback('success', 'Your address has been saved!')

          setTimeout(() => {
            this.$router.push({ name: 'addresses' })
          }, 1000)
        } catch (err) {
          console.error(err)
          this.handleFormFeedback(
            'error',
            'An error occured trying to save your address'
          )
        }
      },

      async handleSubmit() {
        this.$v.$touch()
        this.showFeedback = false
        this.isSubmitting = true

        if (this.$v.$invalid) {
          this.handleFormFeedback('error')
        } else {
          const address = {
            firstName: this.first_name,
            lastName: this.last_name,
            address1: this.address_1,
            address2: this.address_2,
            city: this.city,
            province: this.region,
            country: this.country,
            zip: this.zip_postal,
            phone: this.phone,
          }

          if (this.type === 'new') {
            this.submitNewAddress(address)
          } else {
            this.submitUpdatedAddress(address)
          }
        }
      },
    },

    async created() {
      if (this.type === 'edit') {
        try {
          const { data } = await this.$shopify.client.query({
            query: getCustomerAddressess,
            variables: {
              customerAccessToken: this.accessToken,
            },
          })

          const { defaultAddress, addresses } = data.customer
          const address = addresses.edges.find(this.findAddressToEdit)

          this.primary_address = address.node.id === defaultAddress.id

          this.parseAddressInfo(address.node)
        } catch (err) {
          console.error(err)
          this.$router.push({ name: 'not-found' })
        }
      }
    },
  }
</script>

<template>
  <LazyImage
    :computedTo="to ? to : prismicTo"
    :computedHref="href ? href : prismicHref"
    :computedIsInternal="isInternal"
    :src="src"
    :retinaSrc="retinaSrc"
    :mobileSrc="mobileSrc"
    :mobileRetinaSrc="mobileRetinaSrc"
    :width="computedWidth"
    :height="computedHeight"
    :alt="alt"
    :disableLazy="disableLazy"
  />
</template>

<script>
  import prismicLinkMixin from '@/mixins/prismicLinkMixin';

  export default {
    name: 'PrismicLazyImage',

    mixins: [ prismicLinkMixin ],

    components: {
      LazyImage: () => import('@/components/global/LazyImage')
    },

    props: {
      image: {
        type: Object,
        default: null,
      },
      width: {
        type: [String, Number],
        default: null,
      },
      height: {
        type: [String, Number],
        default: null,
      },
      mobileWidth: {
        type: [String, Number],
        default: 600,
      },
      disableLazy: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      hasHeight() {
        return this.height ? true : false;
      },
      hasWidth() {
        return this.width ? true : false;
      },
      aspectRatio() {
        if(this.hasHeight && this.hasWidth) {
          return this.height / this.width;
        } else if(this.image.dimensions) {
          return this.image.dimensions.height / this.image.dimensions.width;
        }
      },
      computedWidth() {
        if(this.hasWidth) {
          return this.width;
        } else {
          return this.height / this.aspectRatio;
        }
      },
      computedHeight() {
        if(this.hasHeight) {
          return this.height;
        } else {
          return this.width * this.aspectRatio;
        }
      },
      mobileHeight() {
        return this.mobileWidth * this.aspectRatio;
      },
      fit() {
        return this.isProductShot ? 'fillmax' : 'crop';
      },
      src() {
        if(this.image) {
          return this.getImageSrc('normal', this.computedWidth, this.computedHeight);
        }
      },
      retinaSrc() {
        if(this.image) {
          return this.getImageSrc('retina', this.computedWidth, this.computedHeight);
        }
      },
      mobileSrc() {
        if(this.image) {
          if (this.computedWidth > this.mobileWidth) {
            return this.getImageSrc('normal', this.mobileWidth, this.mobileHeight);
          } else {
            return this.src;
          }
        }
      },
      mobileRetinaSrc() {
        if(this.image) {
          if (this.computedWidth > this.mobileWidth) {
            return this.getImageSrc('retina', this.mobileWidth, this.mobileHeight);
          } else {
            return this.retinaSrc;
          }
        }
      },
      alt() {
        if(this.image) {
          return this.image.alt;
        }
      }
    },

    methods: {
      getImageSrc(type, width, height) {
        const pixelDensity = type == 'retina' ? 2 : 1;

        const pixelDensityWidth = width * pixelDensity;
        const pixelDensityHeight = height * pixelDensity;

        return `${this.image.url}?w=${pixelDensityWidth}&max-w=${pixelDensityWidth}&min-w=${pixelDensityWidth}&h=${pixelDensityHeight}&max-h=${pixelDensityHeight}&min-h=${pixelDensityHeight}&fit=${this.fit}`;
      }
    }
  };
</script>

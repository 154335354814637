<template>
  <div v-if="subscription" style="position: relative">
    <div class="account__main__top row">
      <div class="column">
        <h2>Subscription #{{ $route.params.subscription_id }}</h2>
      </div>
    </div>

    <div class="order-overview row grid">
      <div class="column main small-6 large-6">
        <span class="label">
          Order Date

          <span class="label--light">
            {{ getOrderDate(subscription.created_at) }}
          </span>
        </span>
      </div>

      <div class="column small-6 large-4 xlarge-3">
        <span class="label">
          Status

          <span class="label--light">
            {{ subscription.status }}
          </span>
          <button
            v-if="subscription.status === 'active'"
            class="button"
            @click="showCancelModal = true">
            Cancel
          </button>
        </span>
      </div>

      <div
        class="column small-6 large-4 xlarge-3"
        v-if="subscription.status === 'active'">
        <span class="label">
          Next Charge Date

          <span class="label--light">
            {{ getOrderDate(subscription.next_charge_scheduled_at) }}
          </span>

          <button @click="showSkipModal = true">Skip</button>
        </span>
      </div>
    </div>

    <div class="table-scroll">
      <div class="table-wrapper">
        <div class="table order">
          <table>
            <thead>
              <tr>
                <th><span class="label">Product</span></th>
                <th><span class="label">Details</span></th>
                <th><span class="label">Price</span></th>
                <th><span class="label">QTY</span></th>
                <th><span class="label">Total</span></th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{{ subscription.product_title }}</td>
                <td class="small sku">{{ subscription.variant_title }}</td>
                <td class="small">${{ subscription.price }}</td>
                <td>{{ subscription.quantity }}</td>
                <td class="small">
                  ${{ (subscription.quantity * subscription.price).toFixed(2) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="column half">
        <div class="account__card" v-if="address">
          <span class="label label--light">Subscription Address</span>
          <address>
            {{ address.first_name }} {{ address.last_name }}
            <br />
            {{ address.address1 }}
            <br />
            <template v-if="address.address2">
              {{ address.address2 }}
              <br />
            </template>
            {{ address.city }}, {{ address.province_code }} {{ address.zip }}
            <br />
            {{ address.country }}
            <br />
            {{ address.phone }}
          </address>

          <div>
            <button @click="editAddress">Edit Address</button>
          </div>
        </div>
      </div>

      <!-- <div class="column">
        <div class="account__card">
          <span class="label label--light">Payment Method</span>
          <div>
            <img :src="creditCardSrc" :alt="creditCartType" />
            {{ creditCartType }} (•••• •••• •••• 1)
          </div>
        </div>
      </div> -->
    </div>

    <!-- <ConfirmModal
      :showConfirmModal="showSkipModal"
      @closeConfirmModal="showSkipModal = false"
      @actionConfirmed="handleSkip">
      <template v-slot:content>
        <h2>Here might be a Skip title</h2>
      </template>

      <template v-slot:success>
        <h2 v-if="skipMessage">{{ skipMessage }}</h2>
      </template>
    </ConfirmModal>

    <ConfirmModal
      :showConfirmModal="showCancelModal"
      @closeConfirmModal="showCancelModal = false"
      @actionConfirmed="handleCancel">
      <template v-slot:content>
        <h2>Here might be a Cancel Modal title</h2>
      </template>

      <template v-slot:success>
        <h2 v-if="cancelMessage">{{ cancelMessage }}</h2>
      </template>
    </ConfirmModal> -->
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import axios from 'axios'
  import metaMixin from '@/mixins/metaMixin'
  // import ConfirmModal from './ConfirmModal'

  export default {
    name: 'SubscriptionDetail',

    mixins: [metaMixin],

    // components: { ConfirmModal },

    data() {
      return {
        address: null,
        subscription: null,
        creditCartType: 'Visa',
        pageTitle: 'Subscription Details',
        showSkipModal: false,
        showCancelModal: false,
        cancelMessage: '',
        skipMessage: '',
      }
    },

    computed: {
      ...mapState({
        email: state => state.user.data?.email,
        rechargeData: state => state.user.recharge,
      }),

      creditCardSrc() {
        const src = {
          Visa: require('./cards/visa.svg'),
          Bogus: require('./cards/interac-flash.svg'),
          Mastercard: require('./cards/mastercard.svg'),
          'Apple Pay': require('./cards/apple-pay.svg'),
          'Shop Pay': require('./cards/shop-pay.svg'),
          'Google Pay': require('./cards/google-pay.svg'),
          'Interac Flash': require('./cards/interac-flash.svg'),
          'American Express': require('./cards/amex.svg'),
        }

        return src[this.creditCartType]
      },
    },

    methods: {
      ...mapActions(['setRechargeData']),

      async skipNextCharge() {
        try {
          this.$recharge.skipNextCharge(this.subscription.id)

          this.skipMessage = "We've successfully cancelled your subscription."
        } catch (error) {
          console.log(error)
          alert(
            'Something went wrong. Please contact hello@oshensalmon.com if issue persists.'
          )
        }
      },

      async findNextCharge() {
        if (!this.rechargeData) await this.setRechargeData(this.email)

        return this.$recharge.findNextCharge()
      },

      async handleCancel() {
        await this.cancelSubscription()

        this.showCancelModal = false
      },

      async handleSkip() {
        await this.skipNextCharge()

        this.showSkipModal = false
      },

      async cancelSubscription() {
        try {
          this.subscription = await this.$recharge.cancelSubscription(
            this.subscription.id
          )

          this.cancelMessage = "We've successfully cancelled your subscription."
        } catch (error) {
          alert(
            "'We couldn't cancel you're subscription. Please contact hello@oshensalmon.com"
          )
          console.log(error)
        }
      },

      async activateSubscription() {
        try {
          this.subscription = await this.$recharge.activateSubscription(
            this.subscription.id
          )
        } catch (error) {
          alert(
            "We couldn't activate you're subscription. Please contact hello@oshensalmon.com if issue persists."
          )
          console.log(error)
        }
      },

      getOrderDate(dateString) {
        if (!dateString) return '---'

        const d = new Date(dateString)
        const year = d.getFullYear()
        const month = d.getMonth()
        const date = d.getDate()

        return `${date}/${month}/${year}`
      },

      editAddress() {
        this.$router.push({
          name: 'edit-address',
          params: { address_id: this.address.id },
          query: { source: 'recharge' },
        })
      },
    },

    async created() {
      this.subscription = await this.$recharge.fetchSubscription(
        this.$route.params.subscription_id
      )

      this.address = await this.$recharge.fetchAddress(
        this.subscription.address_id
      )

      // this.payment_method = await this.$recharge.fetchPaymentMethod(
      //   this.address.id
      // )

      this.$store.dispatch('setLoadingDone')
    },
  }
</script>

<template>
  <div v-if="product" class="bundle-product">
    <div class="row gutter-small">
      <div class="column small-5">
        <div class="bundle-product__image">
          <ProductTag :text="tagText" />
          <ShopifyLazyImage
            v-if="image"
            :image="image"
            :link="!isUnavailable ? product : null"
            width="200"
            height="225"
          />
        </div>
      </div>
      <div class="column small-7">
        <BrandLink v-if="product.brand" :brand="product.brand" />
        <h3 class="h5">
          <PrismicLink v-if="!isUnavailable" class="title" :link="product" :text="title" />
          <span v-else class="title">{{ title }}</span>
          <Icons
            v-if="hasIcons"
            :isDay="productData.is_am"
            :isNight="productData.is_pm" />
        </h3>
        <prismic-rich-text
          v-if="product.description"
          :field="product.description"
          class="description wysiwyg"
        />
        <ProductSizePicker
          :type="product._meta.uid"
          :sizes="sizes"
          :label="sizeLabel"
          :singleSize="product.size"
          v-model="selections.size"
        />
        <ProductShadePicker
          :type="product._meta.uid"
          v-model="selections.shade"
          :shades="shades"
          :index="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import productMixin from '@/mixins/productMixin'

  import ProductTag from '@/components/shop/ProductTag'
  import ShopifyLazyImage from '@/components/shop/ShopifyLazyImage'
  import BrandLink from '@/components/shop/BrandLink'
  import PrismicLink from '@/components/prismic/PrismicLink'
  import ProductSizePicker from '@/components/shop/ProductSizePicker'
  import ProductShadePicker from '@/components/shop/ProductShadePicker'

  export default {
    name: 'BundleProduct',

    mixins: [ productMixin ],

    components: {
      ProductTag,
      ShopifyLazyImage,
      BrandLink,
      PrismicLink,
      ProductSizePicker,
      ProductShadePicker
    },

    data() {
      return {
        isProductInBundle: true
      }
    },

    props: {
      product: {
        type: Object,
        default: null
      },
      index: {
        type: Number,
        default: 0
      }
    },

    computed: {
      title() {
        return this.product && this.$prismic.asText(this.product.title);
      },
      hasShades() {
        return this.shades && this.shades.length > 0;
      },
      selectedShadeIndex() {
        return this.hasShades ? this.shades.findIndex(shade => shade.name == this.selectedProduct.shade) : 0;
      },
      image() {
        if(!this.shopifyProductMore) return null;

        if(this.hasShades) {
          return this.shades[this.selectedShadeIndex]?.image;
        } else {
          return this.featuredImage;
        }
      }
    },

    watch: {
      selectedProduct: {
        handler: function(newVal) {
          this.$emit('updateProductSelections', newVal);
        },
        deep: true
      }
    },

    async created() {
      await this.initShopping()
    }
  }
</script>

<template>
  <div>
    <h1 class="h2">{{ title }}</h1>
    <template v-if="isReady">
      <div v-if="orders" class="account__table">
        <div class="account__table__header">
          <div class="account__table__row">
            <h3>Order Number</h3>
            <h3>Date Ordered</h3>
            <h3>Order Status</h3>
            <h3>Total</h3>
          </div>
        </div>
        <div class="account__table__body">
          <div v-for="(order, i) in orders" :key="i" class="account__table__row">
            <div>
              <ArrowLink
                :to="'/account/orders/' + getOrderID(order.id)"
                :text="'#' + order.orderNumber"
              />
            </div>
            <div>{{ order.processedAt | date }}</div>
            <div>
              <span>Status: </span>{{ order.fulfillmentStatus | capitalize }}
            </div>
            <div>${{ order.totalPriceV2.amount | currency }}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="account__none">
          You haven't made any orders yet
        </div>
        <Button to="/shop" text="Shop Now" />
      </div>
    </template>
  </div>
</template>

<script>
import ArrowLink from "@/components/global/ArrowLink";
import Button from "@/components/global/Button";

import metaMixin from "@/mixins/metaMixin";

import { getCustomerOrders } from "@/shopify/customer";

export default {
  name: "OrderHistory",

  mixins: [metaMixin],

  components: {
    ArrowLink,
    Button
  },

  data() {
    return {
      title: "Order History",
      orders: null,
      isReady: false
    };
  },

  props: {
    accessToken: {
      type: String,
      default: null
    }
  },

  methods: {
    getOrderID(id) {
      return btoa(id);
    }
  },

  async fetch() {
    this.$store.dispatch("setMetaInfo", {
      title: this.title,
      $liveChat: this.$liveChat
    });
  },

  async created() {
    try {
      const { data } = await this.$shopify.client.query({
        query: getCustomerOrders,
        variables: {
          customerAccessToken: this.accessToken
        }
      });

      const { orders } = data.customer;

      if (orders.edges.length > 0) {
        this.orders = orders.edges.map(order => order.node);
      }

      this.isReady = true;
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

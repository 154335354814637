<template>
  <div v-if="order">
    <h1 class="h2">
      <span class="accessible">Order</span>#{{ order.orderNumber }}
    </h1>
    <span>Order placed: {{ order.processedAt | dateTime }}</span>
    <span
      >Shipping status: {{ this.order.fulfillmentStatus | capitalize }}</span
    >
    <div class="account__table account__table--details">
      <div class="account__table__header">
        <div class="account__table__row">
          <h3>Item</h3>
          <h3>Variant</h3>
          <h3>Quantity</h3>
          <h3>Total</h3>
        </div>
      </div>
      <div v-if="lineItems" class="account__table__body">
        <div
          v-for="(item, i) in lineItems"
          :key="i"
          class="account__table__row product"
        >
          <div class="title">{{ item.title }}</div>
          <div>
            <template v-if="item.variant">{{
              item.variant.title !== "Default Title" ? item.variant.title : ""
            }}</template>
          </div>
          <div>{{ item.quantity }}</div>
          <div>
            <span
              >${{ item.originalTotalPrice.amount | currency }}<br />
              x {{ item.quantity }}<br /></span
            >${{ (item.quantity * item.originalTotalPrice.amount) | currency }}
          </div>
        </div>
      </div>
      <div class="account__table__footer">
        <div
          v-if="shippingAddress || showFulfillment"
          class="account__table__row shipping"
        >
          <div v-if="shippingAddress">
            <h3>Shipping Information</h3>
            <h4>Address</h4>
            <Address :address="shippingAddress" />
          </div>
          <div v-if="showFulfillment">
            <h4>Shipping Method</h4>
            <span class="text" v-if="successfulFulfillment.trackingCompany"
              >Fulfilled by: {{ successfulFulfillment.trackingCompany }}</span
            >
            <template v-if="successfulFulfillment.trackingInfo">
              <span
                class="text"
                v-if="successfulFulfillment.trackingInfo.number"
                >{{ successfulFulfillment.trackingInfo.number }}</span
              >
              <ArrowLink
                :href="successfulFulfillment.trackingInfo.url"
                text="Track Order"
              />
            </template>
          </div>
        </div>
        <div class="account__table__row billing">
          <div v-if="billingAddress">
            <h3>Billing Information</h3>
            <h4>Address</h4>
            <Address :address="billingAddress" />
          </div>
          <div v-if="paymentDetails">
            <h4>Payment Method</h4>
            <span class="light"
              >{{ paymentDetails.credt_card_company }} ({{
                paymentDetails.credit_card_number
              }})</span
            >
          </div>
          <div>
            <table>
              <tbody>
                <tr>
                  <th>Subtotal</th>
                  <td>${{ getSubTotal() | currency }}</td>
                </tr>
                <tr v-if="discount">
                  <th>Discount</th>
                  <td>-${{ discount[0]["amount"] | currency }}</td>
                </tr>
                <tr>
                  <th>Shipping</th>
                  <td>${{ order.totalShippingPriceV2.amount | currency }}</td>
                </tr>
                <tr>
                  <th>Tax</th>
                  <td>${{ order.totalTaxV2.amount | currency }}</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td class="total">
                    ${{ order.totalPriceV2.amount | currency }}
                    {{ order.currencyCode }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

import metaMixin from "@/mixins/metaMixin";

import { getOrderById } from "@/shopify/customer";

import Address from "@/components/shop/Address";
import ArrowLink from "@/components/global/ArrowLink";

export default {
  name: "OrderDetail",

  mixins: [metaMixin],

  components: {
    Address,
    ArrowLink
  },

  data() {
    return {
      order: null,
      billingAddress: null,
      shippingAddress: null,
      paymentDetails: null,
      discount: null
    };
  },

  computed: {
    ...mapState({
      locale: state => state.locale
    }),
    orderId() {
      return this.$route.params.orderId;
    },
    lineItems() {
      if (!this.order) return null;

      return this.order.lineItems.edges.map(item => item.node);
    },
    successfulFulfillment() {
      return this.order.successfulFulfillments;
    },
    showFulfillment() {
      return (
        this.order.fulfillmentStatus == "FULFILLED" &&
        (this.successfulFulfillment.trackingCompany ||
          this.successfulFulfillment.trackingInfo)
      );
    }
  },

  watch: {
    discount(val) {
      if (val) this.getSubTotal();
    }
  },

  methods: {
    async fetchOrder() {
      try {
        const { data } = await this.$shopify.client.query({
          query: getOrderById,
          variables: {
            id: this.orderId
          }
        });

        this.order = data.node;
      } catch (err) {
        console.log(err);
        this.$router.push({ name: "not-found" });
      }
    },
    async fetchTransactionDetails() {
      const adminOrderId = atob(this.orderId);
      const arr = adminOrderId.split("/").slice(-1);
      const orderId = arr[0].split("?").slice(0)[0];

      try {
        const { data } = await axios.get(
          `http://phpstack-490411-2009062.cloudwaysapps.com/order?id=${orderId}`,
          {
            headers: {
              store:
                this.locale == "en-ca"
                  ? process.env.NUXT_ENV_SHOPIFY_SHOP_NAME_CA
                  : process.env.NUXT_ENV_SHOPIFY_SHOP_NAME_US
            }
          }
        );

        const {
          billing_address,
          discount,
          payment_details,
          shipping_address
        } = data;

        this.billingAddress = billing_address;
        this.shippingAddress = shipping_address;
        this.paymentDetails = payment_details;
      } catch (err) {
        console.log(err);
      }
    },
    getSubTotal() {
      const { subtotalPriceV2 } = this.order;

      if (!this.discount) return subtotalPriceV2.amount;

      const discount = this.discount[0]["amount"];

      return (
        parseFloat(subtotalPriceV2.amount) + parseFloat(discount)
      ).toFixed(2);
    }
  },

  async fetch() {
    try {
      this.fetchOrder();

      this.$store.dispatch("setMetaInfo", {
        title: this.order ? `Order #${this.order.orderNumber}` : null,
        $liveChat: this.$liveChat
      });

      await this.fetchTransactionDetails();
    } catch (err) {
      console.log(err);
      this.$router.push({ name: "not-found" });
    }
  }
};
</script>

<template>
  <aside class="column small-12 large-3" ref="stickyParent">
    <div class="sticky" ref="stickyEl">
      <slot></slot>
    </div>
  </aside>
</template>

<script>
  import stickyMixin from '@/mixins/stickyMixin';

  import ArrowLink from '@/components/global/ArrowLink';

  export default {
    name: 'Sidebar',

    mixins: [
      stickyMixin
    ]
  }
</script>

<template>
  <div v-if="page" class="row">
    <div class="column small-12 xxlarge-10 xxxlarge-9">
      <h1 class="h2">{{ page.title | getTitle }}</h1>

      <div class="account__cards row gutter-small">
        <div class="column small-12 large-6">
          <div class="account__card">
            <span class="h3">Your Points</span>
            <span class="points">{{ points_balance }}</span>
          </div>
        </div>

        <div class="column small-12 large-6" v-if="nextReward">
          <div class="account__card">
            <span class="h3">You're Close!</span>
            <span class="h4">{{ nextReward.reward.name }}</span>
            <span>
              {{ nextReward.points_price - points_balance }} Points Away
            </span>
          </div>
        </div>
      </div>

      <RewardsList
        :isAccount="true"
        :rewards="rewards"
        @rewardRedeemed="refreshData"
      />

      <div class="account__points">
        <h3>Points Activity</h3>
        <div
          v-for="(item, i) in pointsTransactionsToShow"
          :key="i"
          class="account__points__item"
        >
          <div>
            <span class="h4">{{ $smile.getTransactionTitle(item) }}</span>
            <span>{{ item.points_change }} Points</span>
          </div>
          <div>{{ date(item.created_at) }}</div>
        </div>

        <LoadMoreButton
          v-if="this.pointsTransactions.length > 3"
          @click="isShowLess = !isShowLess"
          :isShowLess="isShowLess"
        />
      </div>

      <div v-if="page" class="account__card">
        <h2>How Beauty Marks Work</h2>

        <prismic-rich-text :field="page.how_it_works" />

        <Button to="/beauty-marks" text="View Details" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import { date } from '@/helpers'

  import { fetchBeautyMarksData } from '@/prismic/queries'

  import RewardsList from '@/components/global/RewardsList'
  import LoadMoreButton from '@/components/global/LoadMoreButton'
  import Button from '@/components/global/Button'

  import metaMixin from '@/mixins/metaMixin'

  export default {
    name: 'BeautyMarks',

    mixins: [metaMixin],

    components: {
      RewardsList,
      LoadMoreButton,
      Button,
    },

    data() {
      return {
        page: null,
        numPointsActivities: 10,
        isShowLess: false,
        rewards: [],
        pointsTransactions: [],
        nextReward: null,
      }
    },

    computed: {
      ...mapState({
        points_balance: state => state.smile?.points_balance,
        smile_id: state => state.smile?.id,
      }),

      pointsTransactionsToShow() {
        if (!this.isShowLess) return this.pointsTransactions.slice(0, 3)

        return this.pointsTransactions
      },

      numPointsActivitiesShown() {
        if (this.showLess) return this.numPointsActivities

        return 3
      },
    },

    watch: {
      '$store.state.smile': async function(newVal) {
        if (!newVal) return null

        this.pointsTransactions = await this.$smile.fetchPointsTransactions(
          this.smile_id
        )
      },
    },

    methods: {
      date: date,

      setNextReward() {
        if (!this.rewards) return null

        this.nextReward = this.$smile.nextReward(
          this.rewards,
          this.points_balance
        )
      },

      async refreshData() {
        await this.$store.dispatch('fetchSmileAccountData')

        this.pointsTransactions = await this.$smile.fetchPointsTransactions(
          this.smile_id
        )
        this.setNextReward()
      },
    },

    async fetch() {
      this.$store.dispatch('setMetaInfo', {
        title: 'Beauty Marks',
        $liveChat: this.$liveChat,
      })
    },

    async mounted() {
      this.page = await this.$smile.fetchBeautyMarksContent()

      const rewards_products =
        this.$store.state.locale === 'en-ca'
          ? this.page.rewards_products_ca
          : this.page.rewards_products_us

      this.rewards = await this.$smile.fetchRewards(rewards_products)

      this.setNextReward()

      if (!this.pointsTransactions.length && this.smile_id) {
        this.pointsTransactions = await this.$smile.fetchPointsTransactions(
          this.smile_id
        )
      }
    },
  }
</script>

<template>
  <div class="column small-12 large-9">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'Main'
  }
</script>

<template>
  <div class="article__main column small-12 large-10">
    <div v-if="hasAuthors" class="article__byline">
      <h2><span>Written</span> By</h2>
      <Gees :gees="authors" :hasLinks="true" imageSize="36" />
      <Author v-if="hasAuthors" :authors="authors" class="title" />
    </div>
    <div class="article__body wysiwyg has-lede">
      <slot></slot>
    </div>
    <Gate v-if="isGated" />
    <div v-else-if="signoff || hasAuthors" class="article__end row">
      <div class="column small-12 medium-10 large-7">
        <span v-if="signoff" class="signoff">{{ signoff }}</span>
        <div v-if="hasAuthors" class="article__byline" :class="{ 'article__byline--multi' : numAuthors > 1 }">
          <Gees :gees="authors" :hasLinks="true" imageSize="70" />
          <div>
            <Author  :authors="authors" class="title" />
            <template v-if="singleAuthor">
              <a v-if="singleAuthor.twitter_handle" :href="`https://www.twitter.com/${singleAuthor.twitter_handle}`" target="_blank" rel="nofollow">
                <SvgIcon name="twitter" title="Twitter" />
              </a>
              <a v-if="singleAuthor.instagram_handle" :href="`https://www.instagram.com/${singleAuthor.instagram_handle}`" target="_blank" rel="nofollow">
                <SvgIcon name="instagram" title="Instagram" />
              </a>
              <p v-if="singleAuthor.description || singleAuthor.quote">
                {{ singleAuthor.description ? singleAuthor.description : singleAuthor.quote }} <PrismicLink v-if="singleAuthor.learn_more_link" :link="singleAuthor.learn_more_link" text="More" class="plain-link" />
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Gees from '@/components/global/Gees';
  import Author from '@/components/global/Author';
  import Gate from '@/components/pages/article/Gate'
  import SvgIcon from '@/components/global/SvgIcon';
  import PrismicLink from '@/components/prismic/PrismicLink';

  export default {
    name: 'Content',

    components: {
      Gees,
      Author,
      Gate,
      SvgIcon,
      PrismicLink
    },

    props: {
      authors: {
        type: Array,
        default: null
      },
      signoff: {
        type: String,
        default: null
      },
      isGated: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      hasAuthors() {
        return this.authors && this.authors[0] && this.authors[0].name;
      },
      numAuthors() {
        return this.authors.length;
      },
      singleAuthor() {
        if(this.numAuthors == 1) {
          // return this.authors[0].author;
          return this.authors[0];
        }
      }
    }
  }
</script>

<template>
  <div v-if="articles" class="blog-landing__articles__inner">
    <div class="row">
      <div v-for="article in articles1" :key="article._meta.uid" class="column small-12 xlarge-6">
        <ArticleCard
          type="large"
          :article="article"
          imageWidth="965"
          imageHeight="635"
        />
      </div>
    </div>
    <div v-if="articles2.length" class="row">
      <div v-for="article in articles2" :key="article._meta.uid" class="column small-12 xlarge-3">
        <ArticleCard
          type="small"
          :article="article"
          imageWidth="460"
          imageHeight="300"
        />
      </div>
      <Callout v-if="callout" :type="callout" :collection="calloutCollection" />
    </div>
    <div v-if="articles3.length" class="row">
      <div class="column small-12 xlarge-6">
        <ArticleCard
          type="large"
          :article="articles3[0]"
          imageWidth="965"
          imageHeight="635"
          :isEqualHeight="false"
        />
      </div>
      <div v-if="articles4.length" class="column small-12 xlarge-6">
        <ArticleCard
          v-for="article in articles4"
          :key="article._meta.uid"
          type="split"
          :article="article"
          imageWidth="460"
          imageHeight="300"
        />
      </div>
    </div>
    <div v-if="articles5.length" class="row">
      <div v-for="article in articles5" :key="article._meta.uid" class="column small-12 xlarge-3">
        <ArticleCard
          type="small"
          :article="article"
          imageWidth="460"
          imageHeight="300"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ArticleCard from '@/components/global/ArticleCard';
  import Callout from '@/components/global/Callout';

  export default {
    name: 'BlogHomeArticles',

    components: {
      ArticleCard,
      Callout
    },

    props: {
      articles: {
        type: Array,
        default: null
      },
      callout: {
        type: String,
        default: null
      },
      calloutCollection: {
        type: Object,
        default: null
      }
    },

    computed: {
      articles1() {
        return this.articles.slice(0, 2);
      },
      articles2() {
        return this.callout ? this.articles.slice(2, 4) : this.articles.slice(2, 6);
      },
      articles3() {
        return this.callout ? this.articles.slice(4, 5) : this.articles.slice(6, 7);
      },
      articles4() {
        return this.callout ? this.articles.slice(5, 8) : this.articles.slice(7, 10);
      },
      articles5() {
        return this.callout ? this.articles.slice(8, this.articles.length) : this.articles.slice(10, this.articles.length);
      }
    }
  }
</script>

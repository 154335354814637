<template>
  <div class="row" :class="extraClass">
    <div class="column about-title" ref="stickyParent">
      <h2 class="sticky" ref="stickyEl"><prismic-rich-text :field="title" /></h2>
    </div>
    <div class="column about-grid">
      <slot></slot>
    </div>
  </div>
</template>

<script>

  import stickyMixin from '@/mixins/stickyMixin';

  export default {
    name: 'AboutSection',

    mixins: [
      stickyMixin
    ],

    props: {
      title: {
        type: Array,
        default: null
      },
      extraClass: {
        type: String,
        default: ''
      }
    }
  }
</script>

<template>
  <div v-if="product" class="article__marquee__item">
    <ShopifyLazyImage
      v-if="featuredImage"
      :image="featuredImage"
      width="50"
      height="50"
      class="product"
    />
    <span>Shop The <span>Story</span></span> <SvgIcon name="chevron-right" />
  </div>
</template>

<script>
  import productMixin from '@/mixins/productMixin';

  import ShopifyLazyImage from '@/components/shop/ShopifyLazyImage';
  import SvgIcon from '@/components/global/SvgIcon';

  export default {
    name: 'Marquee',

    mixins: [ productMixin ],

    components: {
      ShopifyLazyImage,
      SvgIcon
    },

    props: {
      product: {
        type: Object,
        default: null
      }
    }
  }
</script>

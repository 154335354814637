<template>
  <component
    :is="prismicTo || prismicHref ? (isInternal ? 'nuxt-link' : 'a') : 'button'"
    :to="prismicTo"
    :target="!isInternal ? '_blank' : null">
    <slot>
      <span v-html="computedText" />
    </slot>
  </component>
</template>

<script>
  import prismicLinkMixin from '@/mixins/prismicLinkMixin'

  export default {
    name: 'PrismicLink',

    mixins: [prismicLinkMixin],

    mounted() {
      if (!this.isInternal && this.prismicHref) {
        this.$el.setAttribute('href', this.prismicHref)
      }
    },
  }
</script>

<template>
  <div v-if="tabs && tabs.length" class="tabs">
    <div class="tabs__titles">
      <button
        v-for="(tab, index) in tabs"
        v-if="tab.content || (tab.features && tab.features[0] && tab.features[0].item)"
        :key="index"
        :class="{ 'is-active' : activeTabIndex == index }"
        @click="changeActiveTab(index)"
      >
        {{ tab.title }}
      </button>
    </div>

    <div class="tab__content wysiwyg">
      <div v-if="tabs[activeTabIndex] && tabs[activeTabIndex].features && tabs[activeTabIndex].features[0] && tabs[activeTabIndex].features[0].item" class="features">
        <span v-for="feature in tabs[activeTabIndex].features">{{ feature.item }} • </span>
      </div>
      <prismic-rich-text v-if="tabs[activeTabIndex] && tabs[activeTabIndex].content" :field="tabs[activeTabIndex].content" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Tabs',

    data() {
      return {
        activeTabIndex: 0
      }
    },

    props: {
      tabs: {
        type: Array,
        default: null
      }
    },

    methods: {
      changeActiveTab(index) {
        this.activeTabIndex = index;
      }
    }
  }
</script>

<template>
	<section class="regimen-step">
    <div class="regimen-step__header">
      <h3 class="h2">{{title}}</h3>
      <p>{{description}}</p>
    </div>
    <slot>
    </slot>
  </section>
</template>

<script>
  export default {
    name: 'Step',

    props: {
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      }
    }
  }
</script>

<template>
  <div>
    <h1 class="h2">{{ title }}</h1>
    <AddressForm
      type="edit"
      :accessToken="accessToken"
      :addressId="addressId"
    />
  </div>
</template>

<script>
import metaMixin from "@/mixins/metaMixin";

import AddressForm from "@/components/pages/account/AddressForm";

export default {
  name: "EditAddress",

  mixins: [metaMixin],

  components: {
    AddressForm
  },

  data() {
    return {
      title: "Edit Address"
    };
  },

  props: {
    accessToken: {
      type: String,
      default: null
    },
    addressId: {
      type: String,
      default: null
    }
  },

  fetch() {
    this.$store.dispatch("setMetaInfo", {
      title: this.title,
      $liveChat: this.$liveChat
    });
  }
};
</script>

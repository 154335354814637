<template>
  <aside class="article__sidebar column small-12 large-2" ref="stickyParent">
    <div class="sticky" ref="stickyEl">
      <div class="article__share" tabindex="0" role="button">
        <span class="plus">
          <CopyLink key="copy" :link="url" :isIcon="true" />

          <a
            :href="'http://www.facebook.com/sharer/sharer.php?u=' + url"
            target="_blank"
            rel="nofollow"
            key="facebook"
            ref="facebook"
            @click="shareThis('facebook', $event)"
          >
            <SvgIcon name="facebook" title="Share on Facebook" />
          </a>

          <a
            :href="'https://twitter.com/share?url=' + url + '&text=' + title"
            target="_blank"
            rel="nofollow"
            key="twitter"
            ref="twitter"
            @click="shareThis('twitter', $event)"
          >
            <SvgIcon name="twitter" title="Share on Twitter" />
          </a>
        </span>

        <span>Share</span>
      </div>

      <button
        v-if="showProducts"
        class="article__shop-link"
        @click="
          openQuickShopModal({
            activeProductUid: product._meta.uid,
            type: 'article',
          })
        "
      >
        <ShopifyLazyImage
          v-if="featuredImage"
          :image="featuredImage"
          width="36"
          class="product"
        />

        <div>
          <span>Shop The</span>
          <br />
          Story
        </div>
      </button>
    </div>
  </aside>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import stickyMixin from '@/mixins/stickyMixin'
  import productMixin from '@/mixins/productMixin'

  import ShopifyLazyImage from '@/components/shop/ShopifyLazyImage'
  import SvgIcon from '@/components/global/SvgIcon'
  import CopyLink from '@/components/global/CopyLink'

  export default {
    name: 'ArticleSidebar',

    components: {
      ShopifyLazyImage,
      SvgIcon,
      CopyLink,
    },

    mixins: [stickyMixin, productMixin],

    data() {
      return {
        isOpen: false,
        isDone: false,
      }
    },

    props: {
      showProducts: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: null,
      },
      product: {
        type: Object,
        default: null,
      }
    },

    computed: {
      ...mapGetters(['domain']),
      url() {
        return this.domain + this.$route.path
      },
    },

    methods: {
      ...mapActions(['openQuickShopModal']),
      openShare() {
        this.isOpen = true
      },
      closeShare() {
        this.isOpen = false
      },
      shareThis(type, event) {
        if (process.client) {
          event.preventDefault()
          const options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,'

          const networks = {
            facebook: { width: 600, height: 300 },
            twitter: { width: 600, height: 254 },
            google: { width: 515, height: 490 },
            linkedin: { width: 600, height: 473 },
            pinterest: { width: 600, height: 473 },
            instagram: { width: 600, height: 473 },
          }

          const href = this.$refs[type].getAttribute('href')

          window.open(
            href,
            '',
            options +
              'height=' +
              networks[type].height +
              ',width=' +
              networks[type].width
          )
        }
      },
    },
  }
</script>

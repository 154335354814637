<template>
	<div class="row">
    <div class="column">
      <div class="regimen-footer">
        <h2>Build your <br>regimen<small>(11)</small></h2>
        <div class="regimen-footer__content">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vel arcu laoreet, viverra neque in, malesuada nunc.</p>
          <Frequency type="2" :prices="prices" :salePrices="salePrices" v-model="frequency" />
          <Button text="Add Regimen to Bag"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import Button from '@/components/global/Button';
	import Frequency from '@/components/shop/Frequency';

  export default {
    name: 'Build',

		components: {
      Button,
			Frequency
    },

		props: {
      frequency: {
        type: Object,
				default: () => ({
					selected: 'once',
	        subscriptionFrequency: null
				})
      },
			prices: {
				type: Object,
				default: null
			},
			salePrices: {
				type: Object,
				default: null
			}
    }
  }
</script>

<template>
  <section class="article__related large-pad">
    <div class="row">
      <div class="column small-12 xlarge-4 xxlarge-3">
        <h2>More <span>{{ category.title | getTitle }}</span></h2>
      </div>
      <div class="column small-12 xlarge-8 xxlarge-9">
        <div class="row article-cards">
          <div v-for="(article, index) in articles" :key="index" class="column small-12 large-6">
            <ArticleCard
              :article="article"
              imageWidth="715"
              imageHeight="480"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ArticleCard from '@/components/global/ArticleCard';

  export default {
    name: 'Related',

    components: {
      ArticleCard
    },

    props: {
      category: {
        type: Object,
        default: null
      },
      articles: {
        type: Array,
        default: null
      }
    }
  }
</script>

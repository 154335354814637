<template>
  <Tooltip :isArrowDropdown="true" :isClick="true" align="left">
    <template v-slot:trigger>Filter:</template>
    <template v-slot:content>
      <div class="tooltip__accordion">
        <div
          v-for="(filter, i) in filters"
          :key="i"
          class="tooltip__accordion__item"
          :class="{
            'is-open': isMobileFilterOpen && openMobileFilter == filter.key,
          }">
          <button @click="toggleMobileFilter(filter.key)">
            {{ filter.title }}
            <SvgIcon name="chevron-select" />
          </button>

          <SlideUpDown
            :active="isMobileFilterOpen && openMobileFilter == filter.key">
            <MobileFilterDropdownItems
              :filter="filter"
              :selections.sync="selections[filter.key]"
              :shouldLimitSelections="shouldLimitSelections(filter.key)" />
          </SlideUpDown>
        </div>
      </div>
    </template>
  </Tooltip>
</template>

<script>
  import SlideUpDown from 'vue-slide-up-down'

  import SvgIcon from '@/components/global/SvgIcon'
  import MobileFilterDropdownItems from '@/components/pages/shop-landing/MobileFilterDropdownItems'

  export default {
    components: {
      SlideUpDown,
      SvgIcon,
      MobileFilterDropdownItems,
      Tooltip: () => import('@/components/global/Tooltip'),
    },

    data() {
      return {
        openMobileFilter: null,
        isMobileFilterOpen: false,
      }
    },

    props: {
      filters: {
        type: Array,
        required: true,
      },
      selections: {
        type: Object,
        default: () => {},
      },
    },

    methods: {
      toggleMobileFilter(type) {
        if (!this.isMobileFilterOpen) {
          this.isMobileFilterOpen = true
          this.openMobileFilter = type
          return
        }

        this.isMobileFilterOpen = this.openMobileFilter == type ? false : true
        this.openMobileFilter = type
      },

      shouldLimitSelections(key) {
        return ['concerns', 'highlights'].includes(key)
      },
    },
  }
</script>

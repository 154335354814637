<template>
  <div>
    <h1 class="h2">{{ title }}</h1>
    <template v-if="isReady">
      <div v-if="defaultAddress || addresses" class="row account__addresses">
        <AddressItem
          :address="defaultAddress"
          :isDefault="true"
          @remove="handleRemove"
        />
        <AddressItem
          v-for="address in addresses"
          :key="address.id"
          :address="address"
          @remove="handleRemove"
        />
      </div>
      <div v-else class="account__none">
        No Addresses Saved
      </div>
    </template>
    <Button to="/account/addresses/new" text="New Address" />
    <FormFeedback
      :showFeedback="showFeedback"
      :submitState="submitState"
      :submitMessage="submitMessage"
    />
  </div>
</template>

<script>
  import AddressItem from '@/components/pages/account/AddressItem'
  import FormFeedback from '@/components/global/FormFeedback'
  import Button from '@/components/global/Button'

  import { getCustomerAddressess, deleteAddress } from '@/shopify/customer'

  import formMixin from '@/mixins/formMixin'
  import metaMixin from '@/mixins/metaMixin'

  export default {
    name: 'Addresses',

    mixins: [formMixin, metaMixin],

    components: {
      AddressItem,
      FormFeedback,
      Button,
    },

    data() {
      return {
        title: 'Addresses',
        defaultAddress: null,
        addresses: [],
        isReady: false,
      }
    },

    props: {
      accessToken: {
        type: String,
        default: null,
      },
    },

    methods: {
      async handleRemove(id) {
        try {
          const { data } = await this.$shopify.client.mutate({
            mutation: deleteAddress,
            variables: {
              id: id,
              customerAccessToken: this.accessToken,
            },
          })

          const { customerAddressDelete } = data

          if (customerAddressDelete.customerUserErrors.length) {
            const errorMessage =
              customerAddressDelete.customerUserErrors[0].message

            this.handleFormFeedback('error', errorMessage)
            throw new Error(errorMessage)
          }

          const addresses = this.addresses.filter(address => address.id !== id)

          this.addresses = addresses
        } catch (err) {
          console.error(err)
          this.handleFormFeedback(
            'error',
            'An error occured while deleting the address'
          )
        }
      },
    },

    fetch() {
      this.$store.dispatch('setMetaInfo', {
        title: this.title,
        $liveChat: this.$liveChat,
      })
    },

    async created() {
      const { data } = await this.$shopify.client.query({
        query: getCustomerAddressess,
        variables: {
          customerAccessToken: this.accessToken,
        },
        fetchPolicy: 'no-cache',
      })

      const { defaultAddress, addresses } = data.customer

      this.defaultAddress = defaultAddress
      this.addresses = addresses.edges
        .map(address => address.node)
        .filter(address => address.id !== defaultAddress.id)

      this.isReady = true
    },
  }
</script>

<template>
  <div class="gift-card__value form__row">
    <div class="form__field form__field--small">
      <label for="value">Select Value</label>
      <div class="select">
        <select id="value" v-model="value.selected">
          <option
            v-for="value in values"
            :value="value.name"
            :disabled="!value.available">{{ value.name }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ValuePicker',

    props: {
      values: {
        type: Array,
        default: () => ([])
      },

      value: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>

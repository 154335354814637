<template>
  <ul v-if="menu && menu.links">
    <li v-for="(item, i) in menu.links" :key="i">
      <PrismicLink :text="item.text" :link="item.url" />
    </li>
  </ul>
</template>

<script>
import PrismicLink from "@/components/prismic/PrismicLink";

export default {
  name: "PrismicMenu",

  components: {
    PrismicLink
  },

  props: {
    menu: {
      type: Object,
      default: {}
    }
  }
};
</script>

<template>
  <!-- Note: there are checks on category and author so they don't appear on the articles unnecessarily -->
  <div v-if="articles" class="blog-landing__articles__inner">
    <div v-if="articles1.length" class="row">
      <div class="column small-12 xlarge-6">
        <ArticleCard
          type="large"
          :article="articles1[0]"
          :showCategory="showCategory"
          :showAuthor="showAuthor"
          imageWidth="965"
          imageHeight="635"
          :isEqualHeight="false"
        />
      </div>
      <div v-if="articles2.length" class="column small-12 xlarge-6">
        <ArticleCard
          v-for="article in articles2"
          :key="article._meta.uid"
          type="split"
          :article="article"
          :showCategory="showCategory"
          :showAuthor="showAuthor"
          imageWidth="460"
          imageHeight="300"
        />
      </div>
    </div>
    <div v-if="articles3.length" class="row">
      <div v-for="article in articles3" :key="article._meta.uid" class="column small-12 xlarge-3">
        <ArticleCard
          type="small"
          :article="article"
          :showCategory="showCategory"
          :showAuthor="showAuthor"
          imageWidth="460"
          imageHeight="300"
        />
      </div>
      <Callout v-if="callout" :type="callout" :collection="calloutCollection" />
    </div>
    <div v-if="articles4.length" class="row">
      <div v-for="article in articles4" :key="article._meta.uid" class="column small-12 xlarge-3">
        <ArticleCard
          type="small"
          :article="article"
          :showCategory="showCategory"
          :showAuthor="showAuthor"
          imageWidth="460"
          imageHeight="300"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ArticleCard from '@/components/global/ArticleCard';
  import Callout from '@/components/global/Callout';

  export default {
    name: 'FilteredArticles',

    components: {
      ArticleCard,
      Callout
    },

    props: {
      articles: {
        type: Array,
        default: null
      },
      showCategory: {
        type: Boolean,
        default: true
      },
      showAuthor: {
        type: Boolean,
        default: true
      },
      callout: {
        type: String,
        default: null
      },
      calloutCollection: {
        type: Object,
        default: null
      }
    },

    computed: {
      articles1() {
        return this.articles.slice(0, 1);
      },
      articles2() {
        return this.articles.slice(1, 4);
      },
      articles3() {
        return this.callout ? this.articles.slice(4, 6) : this.articles.slice(4, 8);
      },
      articles4() {
        return this.callout ? this.articles.slice(6, this.articles.length) : this.articles.slice(8, this.articles.length);
      }
    }
  }
</script>

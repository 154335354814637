<template>
  <section class="shop-landing__filter-sort">
    <div class="shop-landing__filter-sort__filter">
      <!--  mobile filters -->
      <MobileFilterDropdown
        v-if="filters"
        :filters="filters"
        :selections.sync="selections" />

      <!-- desktop filters -->
      <span v-if="filters.length > 0">Filter:</span>

      <ul v-if="filters">
        <li v-for="(filter, i) in filters" :key="i">
          <FilterDropdown
            :filter="filter"
            :threeColumns="filter.values.length > 12"
            :selections.sync="selections[filter.key]"
            :shouldLimitSelections="shouldLimitSelections(filter.key)"
            :index="i"
            :currentFilterIndex="currentDesktopFilterIndex"
            @opened="desktopFilterOpened">
            <span
              v-if="selections[filter.key].length"
              class="shop-landing__filter-sort__count">
              <span>{{ selections[filter.key].length }}</span>
            </span>
            {{ filter.title }}
          </FilterDropdown>
        </li>
      </ul>
    </div>

    <div class="shop-landing__filter-sort__sort">
      <AutoWidthSelect
        :id="'sort'"
        label="Sort:"
        :options="sortOptions"
        v-model="selections.sortBy"
        :default="defaultSortBy" />
    </div>
  </section>
</template>

<script>
  import AutoWidthSelect from '@/components/global/AutoWidthSelect'

  import MobileFilterDropdown from '@/components/pages/shop-landing/MobileFilterDropdown'
  import FilterDropdown from '@/components/pages/shop-landing/FilterDropdown'

  import { getTitle } from '@/helpers'

  export default {
    name: 'LandingFilters',
    components: {
      AutoWidthSelect,
      MobileFilterDropdown,
      FilterDropdown,
    },

    data() {
      return {
        currentDesktopFilterIndex: null,
        selections: {
          sortBy: null,
          product_types: [],
          brands: [],
          concerns: [],
          highlights: [],
        },
        sortOptions: {
          relevance: 'Relevance',
          latest: 'Latest',
          oldest: 'Oldest',
          price_high_low: 'Price: High to Low',
          price_low_high: 'Price: Low to High',
        },
      }
    },

    props: {
      filters: {
        type: Array,
        required: false,
      },
      activeFilters: {
        type: Object,
        required: true,
      },
      defaultSortBy: {
        type: String,
        default: null,
      },
    },

    watch: {
      selections: {
        handler(newVal) {
          this.$emit('updateFilters', newVal)
        },
        deep: true,
      },
    },

    methods: {
      desktopFilterOpened($event) {
        this.currentDesktopFilterIndex = $event
      },

      shouldLimitSelections(key) {
        return ['concerns', 'highlights'].includes(key)
      },

      setFilterSelections() {
        //removes page from the $route.query object
        const filterParams = (({ page, ...obj }) => obj)(this.$route.query)

        for (const [key, value] of Object.entries(filterParams)) {
          if (key === 'sortBy') {
            this.selections[key] = value
            continue
          }

          const filterValue = decodeURIComponent(value).split(',')
          this.selections[key] = filterValue
        }
      },

      getTitle,
    },

    mounted() {
      this.setFilterSelections()
    },
  }
</script>

<template>
  <section class="article__header">
    <div class="row">
      <div class="column small-12 large-2">
        <nuxt-link
          v-if="page.category"
          :to="`/beauty-edit/category/${page.category._meta.uid}`"
        >
          {{ page.category.title | getTitle }}
        </nuxt-link>

        <span class="date">{{ publishedDate | fromNow }}</span>
      </div>

      <div class="column small-12 large-10">
        <h1>{{ page.title | getTitle }}</h1>
      </div>

      <div
        class="article__header__image column small-12"
        :class="{ 'large-10': !page.is_featured_image_full_width }"
      >
        <PrismicLazyImage
          v-if="page.featured_image"
          :image="page.featured_image"
          :width="page.is_featured_image_full_width ? 1970 : 1630"
          :disableLazy="true"
        />

        <button @click="openModal('video')">
          <SvgIcon v-if="page.featured_video" name="play" />
        </button>

        <Modal v-if="page.featured_video" type="video">
          <template v-slot:body>
            <div class="video-wrapper">
              <prismic-embed :field="page.featured_video" />
            </div>
          </template>
        </Modal>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapActions } from 'vuex'

  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage'
  import SvgIcon from '@/components/global/SvgIcon'
  import Modal from '@/components/global/Modal'

  export default {
    name: 'ArticleTop',

    components: {
      PrismicLazyImage,
      SvgIcon,
      Modal,
    },

    props: {
      page: {
        type: Object,
        default: null,
      },
      publishedDate: {
        type: String,
        default: null,
      },
    },

    methods: {
      ...mapActions(['openModal']),
    },
  }
</script>

<template>
  <div class="gift-card__type">
    <fieldset>
      <legend class="accessible">Type</legend>
      <input
        id="online"
        type="radio"
        value="online"
        name="gift-card-type"
        v-model="type"
      >
      <label for="online">
        <span>E-Gift Card</span>
        <span>Online purchases only</span>
      </label>
      <input
        id="physical"
        type="radio"
        value="physical"
        name="gift-card-type"
        v-model="type"
        @click="openContactModal('Physical Gift Card')"
      >
      <label for="physical">
        <span>Physical Gift Card</span>
        <span>In-store purchases only</span>
      </label>
    </fieldset>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'Type',

    props: {
      value: {
        type: String,
        default: {}
      }
    },

    computed: {
      type: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      }
    },

    methods: {
      ...mapActions([
        'openContactModal'
      ])
    }
  }
</script>

<template>
  <Section :isVisible="isVisible" type="outro">
    <template v-slot:header>
      <h2>All Set!</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo ex, viverra non congue vel, varius a erat. Nulla egestas lectus ac lacus blandit aliquam.</p>
    </template>
    <template v-slot:buttons>
      <Button text="Back Home" to="/" />
    </template>
  </Section>
</template>

<script>
  import Section from '@/components/pages/quiz/Section';
  import Button from '@/components/global/Button';

  export default {
    name: 'Outro',

    components: {
      Section,
      Button
    },

    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

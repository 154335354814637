<template>
  <div v-if="item" class="concern-card" :class="{'concern-card--shop' : isShop, 'concern-card--portrait' : isPortrait}">
    <PrismicLazyImage
      :to="to"
      :image="item.featured_image"
      width="615"
      height="400"
    />
    <div class="concern-card__content">
      <h4><nuxt-link :to="to">{{ item.title | getTitle }}</nuxt-link></h4>
      <p v-if="item.description">{{ item.description }}</p>
      <ArrowLink :to="to" :text="isShop ? 'Shop All' : 'Read More'" />
    </div>
  </div>
</template>

<script>

  import ArrowLink from '@/components/global/ArrowLink';
  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';

  export default {
    name: 'Concern',

    components: {
      ArrowLink,
      PrismicLazyImage,
    },

    props: {
      item: {
        type: Object,
        default: ''
      },
      isPortrait: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      isCollection() {
        return this.item._meta && this.item._meta.type == 'collection';
      },
      isProductType() {
        return this.item._meta && this.item._meta.type == 'product_type';
      },
      isShop() {
        return this.isCollection || this.isProductType;
      },
      to() {
        if(this.isCollection) {
          return `/shop/collections/${this.item._meta && this.item._meta.uid}`;
        } else if(this.isProductType) {
          return `/shop/${this.item.shopCategory && this.item.shopCategory._meta && this.item.shopCategory._meta.uid}/${this.item._meta && this.item._meta.uid}`;
        } else {
          return `/beauty-edit/${this.item._meta && this.item._meta.uid}`;
        }
      },
      description() {
        if(this.isShop) {
          return this.description;
        } else {
          if(this.content) {
            return truncate(this.article.content[0].text, 100);
          }
          return this.description;
        }
      }
    }
  }
</script>

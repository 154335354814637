<template>
  <section v-if="hasQuotes" class="quotes">
    <div class="row">
      <div class="column">
        <h2 class="h5">What We're Saying</h2>
      </div>
    </div>
    <div class="quotes__slider row">
      <div class="column small-12 large-9 xlarge-10">
        <VueSlickCarousel v-bind="sliderSettings">
          <template #prevArrow="arrowOption">
            <button class="button">
              <SvgIcon name="chevron-left-light" title="Previous" />
            </button>
          </template>
          <blockquote v-for="quote in quotes">
            “{{ quote.quote }}”
            <cite v-if="quote.person">- {{ quote.person }}</cite>
            <Stars v-if="quote.num_stars" :numStars="quote.num_stars" />
          </blockquote>
          <template #nextArrow="arrowOption">
            <button class="button">
              <SvgIcon name="chevron-right-light" title="Next" />
            </button>
          </template>
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel';
  import 'vue-slick-carousel/dist/vue-slick-carousel.css';

  import SvgIcon from '@/components/global/SvgIcon';
  import Stars from '@/components/global/Stars';

  export default {
    name: 'Quotes',

    components: {
      VueSlickCarousel,
      SvgIcon,
      Stars
    },

    data() {
      return {
        sliderSettings: {
          fade: true,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                dots: true
              }
            }
          ]
        }
      }
    },

    props: {
      quotes: {
        type: Array,
        default: null
      }
    },

    computed: {
      hasQuotes() {
        return this.quotes && this.quotes[0] && this.quotes[0].quote;
      }
    }
  }
</script>

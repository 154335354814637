<template>
  <transition>
    <div
      class="quiz__counter"
      v-if="currentSectionIndex > 0 && currentSectionIndex <= numSections"
    >
      <span><span class="accessible">Step</span>{{ currentSectionIndex }}</span> of <span>{{ numSections }}</span>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'Counter',

    props: {
      currentSectionIndex: {
        type: Number,
        default: 0
      },
      numSections: {
        type: Number,
        default: 0
      }
    }
  }
</script>

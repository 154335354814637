<template>
  <transition>
    <div
      class="quiz__progress"
      v-if="currentSectionIndex > 0 && currentSectionIndex <= numSections"
    >
      <button type="button" class="quiz__prev" @click="$emit('goToPrevSection')">
        <SvgIcon name="chevron-left" title="Previous Step" />
      </button>
      <div class="progress-bar">
        <div :style="{ width: (currentSectionIndex / numSections) * 100 + '%' }"></div>
      </div>
      <button type="button" class="quiz__next" @click="$emit('goToNextSection')">
        Next Step <SvgIcon name="chevron-right" />
      </button>
    </div>
  </transition>
</template>

<script>
  import SvgIcon from '@/components/global/SvgIcon';

  export default {
    name: 'Progress',

    components: {
      SvgIcon
    },

    props: {
      currentSectionIndex: {
        type: Number,
        default: 0
      },
      numSections: {
        type: Number,
        default: 0
      }
    }
  }
</script>

<template>
  <header class="header">
    <div class="header__bottom">
      <nuxt-link to="/" class="header__logo">
        <SvgIcon name="g" :title="$config.siteName" />
      </nuxt-link>
    </div>
  </header>
</template>

<script>
  import SvgIcon from '@/components/global/SvgIcon';

  export default {
    name: 'Header',

    components: {
      SvgIcon
    }
  }
</script>

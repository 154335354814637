<template>
	<div class="regimen-list column" ref="stickyParent">
    <div class="sticky" ref="stickyEl">
      <div class="regimen-list__main">
        <div class="regimen-list__tooltip" @click="toggleExpand()" style="display: none">
          <SvgIcon name="checkmark-full" />
          Added to regimen <span>Expand</span>
        </div>
        <div class="regimen-list__top" @click="toggleExpand()">
          <h2>Your Regimen<small>(11)</small></h2>
          <button :class="{'open' : listExpanded}">
            <span v-if="!listExpanded">Expand</span>
            <span v-else>Close</span>
            <SvgIcon name="expand" />
          </button>
        </div>
        <slide-up-down :active="listExpanded" :duration="500">
        <div class="mob-expand-content">
          <ul class="main-list">
            <li>
              <div class="content" @click="changeDropDown(0)" :class="{ 'is-open' : curDropdown == 0 }"><h4>Cleanse (2)</h4><span>$84<SvgIcon name="cheron-down" /></span></div>
              <slide-up-down :active="curDropdown == 0" :duration="300">
              <ul class="hidden-content">
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Cleanser</li>
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Enzyme Cleanser</li>
                <li><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Oil Cleanser</li>
              </ul>
              </slide-up-down>
            </li>
            <li>
              <div class="content" @click="changeDropDown(1)" :class="{ 'is-open' : curDropdown == 1 }"><h4>Tone</h4><span>$84<SvgIcon name="cheron-down" /></span></div>
              <slide-up-down :active="curDropdown == 1" :duration="300">
              <ul class="hidden-content">
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Cleanser</li>
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Enzyme Cleanser</li>
                <li><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Oil Cleanser</li>
              </ul>
              </slide-up-down>
            </li>
            <li>
              <div class="content" @click="changeDropDown(2)" :class="{ 'is-open' : curDropdown == 2 }"><h4>Treat (3)</h4><span>$84<SvgIcon name="cheron-down" /></span></div>
              <slide-up-down :active="curDropdown == 2" :duration="300">
              <ul class="hidden-content">
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Cleanser</li>
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Enzyme Cleanser</li>
                <li><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Oil Cleanser</li>
              </ul>
              </slide-up-down>
            </li>
            <li>
              <div class="content" @click="changeDropDown(3)" :class="{ 'is-open' : curDropdown == 3 }"><h4>Moisturize (3)</h4><span>$84<SvgIcon name="cheron-down" /></span></div>
              <slide-up-down :active="curDropdown == 3" :duration="300">
              <ul class="hidden-content">
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Cleanser</li>
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Enzyme Cleanser</li>
                <li><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Oil Cleanser</li>
              </ul>
              </slide-up-down>
            </li>
            <li>
              <div class="content" @click="changeDropDown(4)" :class="{ 'is-open' : curDropdown == 4 }"><h4>Protect</h4><span>$84<SvgIcon name="cheron-down" /></span></div>
              <slide-up-down :active="curDropdown == 4" :duration="300">
              <ul class="hidden-content">
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Cleanser</li>
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Enzyme Cleanser</li>
                <li><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Oil Cleanser</li>
              </ul>
              </slide-up-down>
            </li>
            <li>
              <div class="content" @click="changeDropDown(5)" :class="{ 'is-open' : curDropdown == 5 }"><h4>Target</h4><span>$84<SvgIcon name="cheron-down" /></span></div>
              <slide-up-down :active="curDropdown == 5" :duration="300">
              <ul class="hidden-content">
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Cleanser</li>
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Enzyme Cleanser</li>
                <li><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Oil Cleanser</li>
              </ul>
              </slide-up-down>
            </li>
            <li>
              <div class="content" @click="changeDropDown(6)" :class="{ 'is-open' : curDropdown == 6 }"><h4>Enhance</h4><span>$0<SvgIcon name="cheron-down" /></span></div>
              <slide-up-down :active="curDropdown == 6" :duration="300">
              <ul class="hidden-content">
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Cleanser</li>
                <li class="is-active"><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Enzyme Cleanser</li>
                <li><SvgIcon name="plus-alt" /><SvgIcon name="checkmark-full" />Oil Cleanser</li>
              </ul>
              </slide-up-down>
            </li>
          </ul>
					<Frequency :prices="prices" :salePrices="salePrices" v-model="frequency" />
        </div>
        </slide-up-down>

        <!-- this button seemed custom enough to use the markup but not the component. -Jesse -->
        <button target="" class="button button--small">
          <span v-if="!listExpanded"><small>$572</small>$677</span>
          Add Regimen to Bag
          <SvgIcon v-if="listExpanded" name="chevron-right" />
        </button>
      </div>
      <p>Questions about a product in your regimen?<ArrowLink @click="openContactModal" text="Chat with us now" /></p>
    </div>
  </div>
</template>

<script>
	import { mapActions } from 'vuex';

  import SlideUpDown from 'vue-slide-up-down'

  import Button from '@/components/global/Button';
  import Frequency from '@/components/shop/Frequency';
  import ArrowLink from '@/components/global/ArrowLink';
	import SvgIcon from '@/components/global/SvgIcon';

  import stickyMixin from '@/mixins/stickyMixin';

  export default {
    name: 'Sidebar',

		mixins: [
      stickyMixin
    ],

		components: {
      SlideUpDown,
      Button,
			Frequency,
      ArrowLink,
			SvgIcon
    },

		data() {
			return {
				curDropdown: -1,
        listExpanded: false
			}
		},

    props: {
			frequency: {
        type: Object,
				default: () => ({
					selected: 'once',
	        subscriptionFrequency: null
				})
      },
			prices: {
				type: Object,
				default: null
			},
			salePrices: {
				type: Object,
				default: null
			}
    },

    methods: {
			...mapActions([
				'openContactModal'
			]),
      changeDropDown: function(val) {
        if (this.curDropdown == val) {
          this.curDropdown = -1;
        } else {
          this.curDropdown = val;
        }
      },
      toggleExpand: function() {
        this.listExpanded = !this.listExpanded;
      }
    },

		mounted() {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

      if (vw > 960) {
        this.listExpanded = true;
      }
    }
  }
</script>

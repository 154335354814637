<template>
  <div class="row">
    <div class="column small-12 xxlarge-10 xxxlarge-8">
      <h1 class="h2">{{ title }}</h1>
      <h3>My Details</h3>
      <form
        class="form"
        :class="{ 'has-errors': $v.$anyError }"
        @submit.prevent="handleSubmit"
      >
        <div class="form__row">
          <div
            class="form__field form__field--half"
            :class="{ 'has-error': $v.first_name.$error }"
          >
            <label for="first_name">First Name</label>
            <input
              id="first_name"
              name="first_name"
              type="text"
              v-model.trim="first_name"
            />
            <span
              class="error"
              v-if="$v.first_name.$error && !$v.first_name.required"
              >This field is required</span
            >
          </div>
          <div
            class="form__field form__field--half"
            :class="{ 'has-error': $v.last_name.$error }"
          >
            <label for="last_name">Last Name</label>
            <input
              id="last_name"
              name="last_name"
              type="text"
              v-model.trim="last_name"
            />
            <span
              class="error"
              v-if="$v.last_name.$error && !$v.last_name.required"
              >This field is required</span
            >
          </div>
        </div>
        <div class="form__row">
          <div class="form__field" :class="{ 'has-error': $v.email.$error }">
            <label for="email">Email</label>
            <input
              id="email"
              name="email"
              type="text"
              v-model.trim="email"
            />
            <span class="error" v-if="$v.email.$error && !$v.email.required"
              >This field is required</span
            >
            <span class="error" v-if="$v.email.$error && !$v.email.email"
              >Please enter a valid email address</span
            >
          </div>
        </div>
        <div class="form__row">
          <div class="form__field" :class="{ 'has-error': $v.password.$error }">
            <label for="password">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              v-model.trim="password"
            />
            <span
              class="error"
              v-if="$v.password.$error && !$v.password.minLength"
              >Your password must be a minimum of
              {{ $v.password.$params.minLength.min }} characters long</span
            >
          </div>
        </div>
        <div class="form__row">
          <div
            class="form__field"
            :class="{ 'has-error': $v.confirm_password.$error }"
          >
            <label for="confirm_password">Confirm Password</label>
            <input
              id="confirm_password"
              name="confirm_password"
              type="password"
              v-model.trim="confirm_password"
            />
            <span
              class="error"
              v-if="
                $v.confirm_password.$error && !$v.confirm_password.minLength
              "
              >Your password must be a minimum of
              {{ $v.confirm_password.$params.minLength.min }} characters
              long</span
            >
            <span
              class="error"
              v-if="$v.confirm_password.$error && !$v.confirm_password.sameAs"
              >Your passwords do not match</span
            >
          </div>
        </div>
        <div class="form__row form__row--button">
          <div class="form__field">
            <Button type="submit" :text="buttonText" :disabled="isSubmitting" />
            <FormFeedback
              :showFeedback="showFeedback"
              :submitState="submitState"
              :submitMessage="submitMessage"
            />
          </div>
        </div>
      </form>
      <div class="account__cards">
        <!-- <div class="account__card">
          <h3>My Regimen</h3>
          Placeholder
        </div>
        <div class="account__card">
          <h3>My Skintone</h3>
          Placeholder
        </div> -->
        <div class="account__card">
          <h3>Beauty Marks</h3>
          <span class="points">{{ points_balance }}</span>
          <Button
            to="/account/beauty-marks"
            text="Start Redeeming"
            size="small"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { required, email, sameAs, minLength } from "vuelidate/lib/validators";

import formMixin from "@/mixins/formMixin";
import metaMixin from "@/mixins/metaMixin";

import { updateCustomerProfile } from "@/shopify/customer";

import Button from "@/components/global/Button";
import FormFeedback from "@/components/global/FormFeedback";

export default {
  name: "Profile",

  mixins: [formMixin, metaMixin],

  components: {
    Button,
    FormFeedback
  },

  data() {
    return {
      title: "My Profile",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      defaultButtonText: "Save Changes"
    };
  },

  props: {
    accessToken: {
      type: String,
      default: null
    }
  },

  computed: {
    ...mapState({
      userData: state => state.user.data,
      points_balance: state => state.smile?.points_balance
    })
  },

  validations: {
    first_name: {
      required
    },
    last_name: {
      required
    },
    email: {
      required,
      email
    },
    password: {
      minLength: minLength(7)
    },
    confirm_password: {
      minLength: minLength(7),
      sameAs: sameAs("password")
    }
  },

  methods: {
    resetPasswordFields() {
      this.password = "";
      this.confirm_password = "";
      this.$v.$reset();
    },
    async handleSubmit() {
      this.$v.$touch();
      this.showFeedback = false;
      this.isSubmitting = true;

      if (this.$v.$invalid) {
        this.handleFormFeedback("error");
      } else {
        const customer = {
          firstName: this.first_name,
          lastName: this.last_name,
          email: this.email
        };

        if (this.password) {
          customer.password = this.password;
        }

        try {
          const { data } = await this.$shopify.client.mutate({
            mutation: updateCustomerProfile,
            variables: {
              customerAccessToken: this.accessToken,
              customer: customer
            }
          });

          const { customerUpdate } = data;

          // If there's an error in the shopify mutation
          if (customerUpdate.customerUserErrors.length) {
            const errorMessage = customerUpdate.customerUserErrors[0].message;

            this.handleFormFeedback('error', errorMessage);
            throw new Error(errorMessage);
          }

          // If a new access token was generated, lets delete the old one and set the new one
          if (customerUpdate.customerAccessToken) {
            this.$store.dispatch("removeAccessToken");
            this.$store.dispatch(
              "setAccessToken",
              customerUpdate.customerAccessToken
            );
          }

          this.handleFormFeedback("success", "Your profile has been saved!");
          this.resetPasswordFields();
        } catch (err) {
          console.log(err);
          this.handleFormFeedback(
            "error",
            "An error occured while saving your account"
          );
          this.resetPasswordFields();
        }
      }
    }
  },

  async fetch() {
    this.$store.dispatch("setMetaInfo", {
      title: this.title,
      $liveChat: this.$liveChat
    });

    this.first_name = this.userData.firstName;
    this.last_name = this.userData.lastName;
    this.email = this.userData.email;
  }
};
</script>

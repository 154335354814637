<template>
  <ul>
    <li v-for="(item, i) in filter.values" :key="i">
      <input
        type="checkbox"
        class="radio"
        :name="item._meta.uid"
        :id="`${item._meta.uid}-value`"
        :value="item._meta.uid"
        :checked="selections.includes(item._meta.uid)"
        @click="handleInput"
      />
      <label :for="`${item._meta.uid}-value`">
        {{ getTitle(item.title) }}
      </label>
    </li>
  </ul>
</template>

<script>
  import { getTitle } from '@/helpers'

  export default {
    props: {
      filter: {
        type: Object,
        required: true,
      },
      selections: {
        type: Array,
        default: () => []
      },
      shouldLimitSelections: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      handleInput({ target: { value } }) {
        // if value is already array lets deselect it
        if (this.selections.includes(value)) {
          const selections = this.selections.filter(el => el !== value)

          this.$emit('update:selections', selections)
          return
        }

        // limit concerns / highlights to only one selection
        if (this.shouldLimitSelections) {
          this.$emit('update:selections', [value])
          return
        }

        // add the value to the array
        this.$emit('update:selections', [...this.selections, value])
      },

      getTitle
    }
  }
</script>

<template>
  <transition>
    <div v-if="isVisible" class="quiz__section" :class="type && `quiz__section--${type}`">
      <div class="quiz__section__wrap">
        <div v-if="hasHeader" class="quiz__section__header">
          <slot name="header"></slot>
        </div>
        <div v-if="hasContent" class="quiz__section__content">
          <slot name="content"></slot>
        </div>
        <div v-if="hasButtons" class="quiz__section__buttons">
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'Section',

    props: {
      isVisible: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: null
      }
    },

    computed: {
      hasHeader() {
        return this.$slots.header;
      },
      hasContent() {
        return this.$slots.content;
      },
      hasButtons() {
        return this.$slots.buttons;
      }
    }
  }
</script>

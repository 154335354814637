<template>
  <div>
    <div class="product-single__main__gallery row gutter-small">
      <div class="product-single__main__gallery__carousel column small-12">
        <VueSlickCarousel v-if="numImages > 0" ref="carousel" v-bind="carouselSettings" @init="goToSlide(0)" @beforeChange="beforeChange" :key="carouselKey">
          <template #prevArrow="arrowOption">
            <button>
              <SvgIcon name="chevron-up" title="Previous" />
            </button>
          </template>
          <button
            v-if="showFeaturedImage"
            @click="goToSlide(featuredImageIndex)"
            :class="{ 'is-active' : currentSlideIndex == featuredImageIndex }"
          >
            <ShopifyLazyImage
              :image="featuredImage"
              width="150"
              class="square"
              :disableLazy="true"
            />
          </button>
          <button
            v-if="hasShades && !isCollage"
            @click="goToSlide(shadeThumbIndex)"
            :class="{ 'is-active' : currentSlideIndex == shadeThumbIndex }"
          >
            <ShopifyLazyImage
              v-for="(shade, index) in shades"
              v-if="index == currentShadeIndex && shade.image"
              :key="shade.name"
              :image="shade.image"
              width="150"
              :alt="shade.name"
              class="square"
            />
          </button>
          <button
            v-if="additionalImages.length > 0"
            v-for="(image, index) in additionalImages"
            :key="image.url"
            @click="goToSlide(additionalImagesIndex + index)"
            :class="{ 'is-active' : currentSlideIndex == additionalImagesIndex + index }"
          >
            <PrismicLazyImage
              :image="image.image"
              width="150"
              class="square"
              :class="image.is_product_shot || image.is_swatch ? 'product' : 'cover'"
            />
          </button>
          <template #nextArrow="arrowOption">
            <button>
              <SvgIcon name="chevron-down" title="Next" />
            </button>
          </template>
        </VueSlickCarousel>
        <div v-if="video" class="product-single__main__gallery__video">
          <button @click="openModal('video')">
            <div class="video-wrapper">
              <Video v-if="previewVideo" :video="previewVideo" :isAutoplay="true" />
              <LazyImage
                v-else
                :src="video.thumbnail_url"
                width="150"
                height="150"
                class="cover"
              />
              <div class="icon">
                <SvgIcon name="play-white" />
              </div>
            </div>
            <span>How To<br />Use</span>
          </button>
          <Modal type="video">
            <template v-slot:body>
              <Video :video="video" />
            </template>
          </Modal />
        </div>
      </div>
      <div class="product-single__main__gallery__image column small-12" :class="{ 'is-collage' : isCollage }">
        <template v-if="!isCollage">
          <div v-if="showFeaturedImage && currentSlideIndex == featuredImageIndex">
            <ShopifyLazyImage
              :image="featuredImage"
              width="800"
              class="product"
            />
          </div>
          <div v-if="hasShades && currentSlideIndex == shadeThumbIndex">
            <ShopifyLazyImage
              v-for="(shade, index) in shades"
              v-if="index == currentShadeIndex && shade.image"
              :key="shade.name"
              :image="shade.image"
              width="800"
              :alt="shade.name"
              class="product"
            />
          </div>
          <div
            v-if="additionalImages.length > 0 && currentSlideIndex == additionalImagesIndex + index"
            v-for="(image, index) in additionalImages"
            :key="image.url"
          >
            <PrismicLazyImage
              :image="image.image"
              width="800"
              :class="{ 'product' : image.is_product_shot || image.is_swatch }"
            />
          </div>
        </template>
        <template v-else>
          <div>
            <ShopifyLazyImage
              v-if="featuredImage"
              :image="featuredImage"
              width="375"
              height="750"
              class="cover collage-product"
            />
            <div
              v-if="additionalImages.length > 0 && currentSlideIndex == index"
              v-for="(image, index) in additionalImages"
              :key="image.image.url"
              class="collage-lifestyle"
            >
              <PrismicLazyImage
                :image="image.image"
                width="550"
                height="550"
                :class="{ 'product' : image.is_product_shot || image.is_swatch }"
              />
            </div>
            <div v-if="currentSwatch">
              <PrismicLazyImage
                :key="currentSwatch.image.url"
                :image="currentSwatch.image"
                width="200"
                class="product collage-swatch"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  import VueSlickCarousel from 'vue-slick-carousel';
  import 'vue-slick-carousel/dist/vue-slick-carousel.css';

  import ShopifyLazyImage from '@/components/shop/ShopifyLazyImage';
  import LazyImage from '@/components/global/LazyImage';
  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';
  import Video from '@/components/global/Video';
  import SvgIcon from '@/components/global/SvgIcon';
  import Modal from '@/components/global/Modal';

  export default {
    name: 'Gallery',

    components: {
      VueSlickCarousel,
      ShopifyLazyImage,
      LazyImage,
      PrismicLazyImage,
      Video,
      SvgIcon,
      Modal
    },

    props: {
      shades: {
        type: Array,
        default: () => ([])
      },
      hoveredShadeName: {
        type: String,
        default: null
      },
      selectedShadeName: {
        type: String,
        default: null
      },
      isCollage: {
        type: Boolean,
        default: false
      },
      otherImages: {
        type: Array,
        default: null
      },
      featuredImage: {
        type: Object,
        default: null
      },
      video: {
        type: Object,
        default: null
      },
      previewVideo: {
        type: Object,
        default: null
      },
      isKit: {
        type: Boolean,
        default: false
      },
      isBundle: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        carouselKey: 0,
        isInitialLoad: true,
        isTablet: false,
        currentSlideIndex: 0,
        carouselSettings: {
          slidesToShow: 4,
          focusOnSelect: true,
          vertical: true,
          verticalSwiping: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                vertical: false,
                verticalSwiping: false,
                slidesToShow: 6
              }
            },
            {
              breakpoint: 480,
              settings: {
                vertical: false,
                verticalSwiping: false,
                slidesToShow: 4
              }
            }
          ]
        }
      }
    },

    computed: {
      ...mapState({
        locale: state => state.locale,
      }),
      hasShades() {
        return this.shades && this.shades.length > 0;
      },
      showFeaturedImage() {
        if(this.hasShades && !this.isKit) {
          return false;
        } else {
          return this.featuredImage ? true : false;
        }
      },
      featuredImageIndex() {
        return this.showFeaturedImage ? 0 : null;
      },
      shadeThumbIndex() {
        return this.showFeaturedImage && this.hasShades && !this.isBundle ? 1 : 0;
      },
      additionalImagesIndex() {
        return this.shadeThumbIndex + (this.isCollage ? 0 : 1);
      },
      hoveredShadeIndex() {
        var index = this.shades.findIndex(shade => shade.name == this.hoveredShadeName);

        return this.hasShades ? (index > 0 ? index : 0) : 0;
      },
      selectedShadeIndex() {
        return this.hasShades ? this.shades.findIndex(shade => shade.name == this.selectedShadeName) : 0;
      },
      currentShadeIndex() {
        return this.hoveredShadeIndex ? this.hoveredShadeIndex : this.selectedShadeIndex;
      },
      selectedShadeSKU() {
        const shade = this.hasShades && this.shades.find(shade => shade.name == this.selectedShadeName);

        return shade && shade.sku;
      },
      hoveredShadeSKU() {
        const shade = this.hasShades && this.shades.find(shade => shade.name == this.hoveredShadeName);

        return shade && shade.sku;
      },
      currentShadeSKU() {
        return this.hoveredShadeSKU ? this.hoveredShadeSKU : this.selectedShadeSKU;
      },
      localizedSkuType() {
        return (this.locale === 'en-ca' ? 'canadian' : 'us') + '_variant_associated_sku';
      },
      additionalImages() {
        let allImages;
        if(this.hasShades && this.currentShadeSKU) {
          const images = this.otherImages.filter(image => {
            return image[this.localizedSkuType] == this.currentShadeSKU || !image[this.localizedSkuType];
          });

          allImages = images.sort((a, b) => {
            if (a[this.localizedSkuType] && !b[this.localizedSkuType]) {
              return -1;
            }

            if (!a[this.localizedSkuType] && b[this.localizedSkuType]) {
              return 1;
            }

            return 0;
          });
        } else {
          allImages = this.otherImages;
        }

        if(this.isCollage) {
          return allImages.filter(image => {
            return !image.is_swatch;
          })
        } else {
          return allImages;
        }
      },
      currentSwatch() {
        if(this.isCollage && this.hasShades && this.currentShadeSKU) {
          return this.otherImages.find(image => {
            return image[this.localizedSkuType] == this.currentShadeSKU && image.is_swatch;
          });
        }
      },
      numImages() {
        return (this.showFeaturedImage && !this.isCollage ? 1 : 0) + (this.hasShades && !this.isCollage ? 1 : 0) + (this.additionalImages ? this.additionalImages.length : 0);
      },
      isSlider() {
        return this.numImages > (this.isTablet ? 6 : 4);
      },
    },

    watch: {
      hoveredShadeName() {
        if(!this.isCollage) {
          this.goToSlide(this.shadeThumbIndex);
        }

        this.carouselKey += 1;
      },
      selectedShadeName() {
        if(!this.isCollage && !this.isInitialLoad) {
          this.goToSlide(this.shadeThumbIndex);
        }

        this.isInitialLoad = false;

        this.carouselKey += 1;
      }
    },

    mounted() {
      this.isTablet = window.innerWidth > 480 && window.innerWidth < 769;

      this.carouselSettings.speed = 501;
    },

    methods: {
      ...mapActions([
        'openModal'
      ]),
      goToSlide(index) {
        this.currentSlideIndex = index;
        var carousel = this.$refs.carousel;
        if(carousel) {
          carousel.goTo(index);
        }
      },
      beforeChange(currentPosition, nextPosition) {
        if(this.isSlider) {
          this.goToSlide(nextPosition, true);
        }
      }
    }
  }
</script>

<template>
  <div>
    <PageTitle v-if="isLoading" :isAlone="true">
      <p>Loading...</p>
    </PageTitle>
    <template v-else>
      <section v-if="articles" class="blog-landing__articles">
        <component
          :is="isBlogHome ? 'BlogHomeArticles' : 'FilteredArticles'"
          :articles="articles"
          :showCategory="showCategory"
          :showAuthor="showAuthor"
          :callout="callout"
          :calloutCollection="calloutCollection"
        />
        <div v-if="hasMore" class="blog-landing__articles__more row">
          <div v-if="hasMore" class="load-more column small-12">
            <LoadMoreButton @click="loadMore" :disabled="isLoadingMore" />
          </div>
        </div>
      </section>
      <PageTitle v-else :isAlone="true">
        <p>Sorry, there are no articles here yet!</p>
      </PageTitle>
    </template>
  </div>
</template>

<script>
  import {
    fetchAllArticles,
    fetchArticlesByCategory,
    fetchArticlesByAuthor,
    fetchArticlesByCategoryAndAuthor,
    fetchBlogLandingCallout,
    fetchBlogCategoryCalloutByUID,
    fetchAuthorCalloutByUID
  } from '@/prismic/queries';

  import BlogHomeArticles from '@/components/pages/blog-landing/BlogHomeArticles';
  import FilteredArticles from '@/components/pages/blog-landing/FilteredArticles';
  import ArticleCard from '@/components/global/ArticleCard';
  import LoadMoreButton from '@/components/global/LoadMoreButton';
  import PageTitle from '@/components/global/PageTitle';

  export default {
    name: 'Articles',

    components: {
      BlogHomeArticles,
      FilteredArticles,
      ArticleCard,
      LoadMoreButton,
      PageTitle
    },

    data() {
      return {
        articles: null,
        callout: null,
        calloutCollection: null,
        isLoadingMore: false,
        isInitial: true,
        endCursor: null,
        hasMore: false,
        isLoading: true
      }
    },

    props: {
      currentCategory: {
        type: Object,
        default: null
      },
      currentAuthor: {
        type: Object,
        default: null
      }
    },

    computed: {
      isBlogHome() {
        return !this.currentCategory && !this.currentAuthor;
      },
      showCategory() {
        return this.isBlogHome ? true : (this.currentCategory ? false : true);
      },
      showAuthor() {
        return this.isBlogHome ? true : (this.currentAuthor ? false : true);
      },
      numResults() {
        if(this.isBlogHome) {
          return this.isInitial ? (this.callout ? 12 : 14) : 8;
        } else {
          return this.isInitial ? (this.callout ? 14 : 16) : 8;
        }
      }
    },

    watch: {
      currentCategory() {
        this.reInit();
      },
      currentAuthor() {
        this.reInit();
      }
    },

    methods: {
      loadMore() {
        this.fetchArticles();
      },
      async fetchArticles() {
        this.isLoadingMore = true;

        const info = this.getLandingPageInfo();

        const { edges, pageInfo } = await this.queryArticles(info);

        this.setArticles(edges, pageInfo);

        this.isLoading = false;
        this.isLoadingMore = false;
        this.isInitial = false;
      },
      async fetchCallouts() {
        if(this.isBlogHome) {
          try {
            const { data } = await this.$apolloProvider.defaultClient.query({
              query: fetchBlogLandingCallout
            });

            const calloutResponse = data.allBlog_landings.edges;

            if(calloutResponse.length) {
              const response = calloutResponse[0].node;

              this.callout = response.callout;
              this.calloutCollection = response.callout_collection;
            }
          } catch(err) {
            console.log(err);
          }
        } else {
          try {
            if(this.currentCategory) {
              const { data } = await this.$apolloProvider.defaultClient.query({
                query: fetchBlogCategoryCalloutByUID,
                variables: {
                  uid: this.currentCategory._meta.uid
                }
              });

              const categoryCalloutResponse = data.allBlog_categorys.edges;

              if(categoryCalloutResponse.length) {
                const response = categoryCalloutResponse[0].node;

                this.callout = response.callout;
                this.calloutCollection = response.callout_collection;
              }
            }

            if(this.currentAuthor) {
              const { data } = await this.$apolloProvider.defaultClient.query({
                query: fetchAuthorCalloutByUID,
                variables: {
                  uid: this.currentAuthor._meta.uid
                }
              });

              const authorCalloutResponse = data.allAuthors.edges;

              if(authorCalloutResponse.length) {
                const response = authorCalloutResponse[0].node;

                this.callout = response.callout;
                this.calloutCollection = response.callout_collection;
              }
            }
          } catch(err) {
            console.log(err);
          }
        }
      },
      getLandingPageInfo() {
        if(this.isBlogHome) {
          return {
            query: fetchAllArticles
          }
        }

        if(this.currentCategory && this.currentAuthor) {
          return {
            query: fetchArticlesByCategoryAndAuthor,
            categoryId: this.currentCategory._meta.id,
            authorId: this.currentAuthor._meta.id
          }
        }

        if(this.currentCategory) {
          return {
            query: fetchArticlesByCategory,
            categoryId: this.currentCategory._meta.id
          }
        }

        if(this.currentAuthor) {
          return {
            query: fetchArticlesByAuthor,
            authorId: this.currentAuthor._meta.id
          }
        }
      },
      async queryArticles({ query, categoryId, authorId }) {
        try {
          const { data } = await this.$apolloProvider.defaultClient.query({
            query: query,
            variables: {
              numResults: this.numResults,
              endCursor: this.endCursor,
              categoryId: categoryId,
              authorId: authorId
            }
          });

          return data.allArticles;
        } catch(err) {
          console.log(err);
        }
      },
      setArticles(edges, pageInfo) {
        if(edges.length) {
          this.hasMore = pageInfo.hasNextPage;
          this.endCursor = pageInfo.endCursor;

          const newArticles = edges.map(item => item.node);

          if(this.isInitial) {
            this.articles = newArticles;
          } else {
            this.articles = [...this.articles, ...newArticles];
          }
        }
      },
      reInit() {
        this.articles = null;
        this.isLoadingMore = false;
        this.isInitial = true;
        this.endCursor = null;
        this.hasMore = false;
        this.isLoading = true;

        this.fetchArticles();
      }
    },

    async fetch() {
      await this.fetchCallouts();
      await this.fetchArticles();
    }
  }
</script>

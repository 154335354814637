<template>
  <div v-if="address" class="column small-12 large-6 xlarge-4">
    <div class="account__address">
      <h3 v-if="isDefault">Primary</h3>
      <Address :address="address" />
      <div class="account__address__actions">
        <nuxt-link :to="'/account/addresses/' + address.id">Edit</nuxt-link>
        <button @click="$emit('remove', address.id)">Remove</button>
      </div>
    </div>
  </div>
</template>

<script>
  import Address from '@/components/shop/Address';

  export default {
    name: 'AddressItem',

    components: {
      Address
    },

    props: {
      address: {
        type: Object,
        default: null
      },
      isDefault: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

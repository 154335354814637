<template>
  <div class="about-card">
    <PrismicLazyImage
      :link="link"
      :to="to"
      :image="image"
      width="400"
      height="400"
    />
    <div class="about-card__title">
      <h3>{{ title }}</h3>
      <span class="h4" v-if="subTitle">{{ subTitle }}</span>
      <div v-if="twitterHandle || instagramHandle" class="socials">
        <a v-if="twitterHandle" :href="`https://twitter.com/${twitterHandle}`" target="_blank" rel="nofollow">
          <SvgIcon name="twitter" title="Twitter" />
        </a>
        <a v-if="instagramHandle" :href="`https://instagram.com/${instagramHandle}`" target="_blank" rel="nofollow">
          <SvgIcon name="instagram-alt" title="Instagram" />
        </a>
      </div>
    </div>
    <p v-if="description">{{ description }}</p>
    <ArrowLink
      v-if="to || link"
      :to="to"
      :link="link"
      text="Learn More"
    />
  </div>
</template>

<script>
  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';
  import PrismicLink from '@/components/prismic/PrismicLink';
  import ArrowLink from '@/components/global/ArrowLink';
  import SvgIcon from '@/components/global/SvgIcon';

  export default {
    name: 'AboutCard',

    components: {
      PrismicLazyImage,
      PrismicLink,
      ArrowLink,
      SvgIcon
    },

    props: {
      image: {
        type: Object,
        default: null
      },
      title: {
        type: String,
        default: null
      },
      subTitle: {
        type: String,
        default: null
      },
      twitterHandle: {
        type: String,
        default: null
      },
      instagramHandle: {
        type: String,
        default: null
      },
      description: {
        type: String,
        default: null
      },
      link: {
        type: Object,
        default: null
      },
      to: {
        type: String,
        default: null
      }
    },

    computed: {
      linkType() {
        return this.link;
      }
    }
  }
</script>

<template>
  <div>
    <h2>Subscriptions</h2>

    <div class="table-scroll" v-if="showTable">
      <div class="table-wrapper">
        <div class="table history">
          <table>
            <thead v-if="isLoaded">>
              <tr>
                <th>Subscription</th>
                <th>Product</th>
                <th>Status</th>
                <th>Created</th>
                <th>Next Charge</th>
              </tr>
            </thead>

            <tbody v-if="!isLoaded">
              <tr>
                <td><span class="loading"></span></td>
                <td><span class="loading"></span></td>
                <td><span class="loading"></span></td>
                <td><span class="loading"></span></td>
                <td><span class="loading"></span></td>
                <td><span class="loading"></span></td>
              </tr>
            </tbody>

            <tbody v-else>
              <tr v-for="(sub, i) in subscriptions" :key="i">
                <td>
                  <router-link
                    :to="{
                      name: 'subscription-detail',
                      params: { subscription_id: sub.id },
                    }"
                    class="link">
                    #{{ sub.id }}
                  </router-link>
                </td>

                <td>{{ sub.product_title }}</td>
                <td>{{ capitalize(sub.status) }}</td>
                <td>{{ date(sub.created_at) }}</td>
                <td>{{ date(sub.next_charge_scheduled_at) }}</td>
                <td>{{ frequency(sub.order_interval_frequency) }}</td>
                <!-- <td class="small">${{ sub.price.toFixed(2) }}</td> -->

                <td>
                  <button
                    v-if="sub.status === 'ACTIVE'"
                    class="button"
                    @click="handleCancel(sub.id)">
                    Cancel
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-if="isLoaded && !subscriptions.length">
      You don't have any subscriptions yet.
    </div>
    <div>Redirecting...</div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import metaMixin from '@/mixins/metaMixin'
  import axios from "axios";

  export default {
    name: 'Subscriptions',

    mixins: [metaMixin],

    data() {
      return {
        subLink: '',
        isLoaded: false,
        subscriptions: [],
        pageTitle: 'Subscriptions',
      }
    },

    computed: {
      ...mapState({
        email: state => state.user.data?.email,
        created_at: state => state.user.data?.created_at,
        rechargeData: state => state.user.recharge,
      }),

      showTable() {
        return (
          (!this.isLoaded && !this.subscriptions.length) ||
          (this.isLoaded && this.subscriptions.length)
        )
      },
    },

    methods: {
      ...mapActions(['setLoadingDone', 'setRechargeData']),

      getOrderDate(dateString) {
        if (!dateString) return '---'

        const d = new Date(dateString)
        const date = d.getDate()
        const month = d.getMonth()
        const year = d.getFullYear()

        return `${date}/${month}/${year}`
      },

      async fetchData() {
        if (!this.rechargeData) await this.setRechargeData(this.email)

        if (!this.rechargeData) {
          this.isLoaded = !this.isLoaded
          return null
        }

        this.subscriptions = await this.$recharge.fetchAllSubscriptions(
          this.rechargeData.id
        )

        this.isLoaded = !this.isLoaded
      },

      date(string) {
        if (!string) return '---'

        const d = new Date(string)
        const year = d.getFullYear()
        const month = d.getMonth()
        const date = d.getDate()
        const months = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ]
        return `${months[month]} ${date}, ${year}`
      },

      capitalize(string) {
        const words = string.match(/([^-]+)/g) || []

        return words
          .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase())
          .join(' ')
      },

      frequency(value) {
        const frequencyDict = {
          1: 'Once a Week',
          2: 'Every Two Weeks',
          4: 'Once a Month',
        }

        return frequencyDict[value]
      },

      async handleCancel(subId) {
        const cancelSub = await this.$recharge.cancelSubscription(subId)

        this.subscriptions = [...this.subscriptions].map(sub =>
          sub.id === cancelSub.id ? cancelSub : sub
        )
      },
    },

    watch: {

      //`https://geebeauty.${tld}/api/multipass`,
      //window.location.origin+`/api/multipass`,
      email() {
      },
    },

    async mounted() {


        const tld = this.locale === 'en-ca' ? 'ca' : 'com'
        const {data} = axios.post(
          window.location.origin+`/api/multipass`,
          {
            email: this.email,
            return_to: 'https://checkout.geebeauty.ca/pages/subscriptions',
          },
          {headers: {locale: this.locale}}
        ).then(function(response) {
          window.open(response.data.loginUrl, '_blank');
          //window.location = response.data.loginUrl;
          }
        )

      this.setLoadingDone()
    },
  }
</script>

<style lang="scss" scoped>
  .loading {
    height: 15px;
    background-color: lightgray;
    display: block;
  }
</style>


<template>
  <section class="article__gate">
    <h2 v-if="title" class="h4">{{ title }}</h2>
    <p v-if="content" class="large">{{ content }}</p>
    <div class="form">
      <div class="form__row form__row--button">
        <div class="form__field">
          <Button @click="openRegisterWithNewsletterCheckedExpand" text="Sign Up" size="small" :isAltBG="true" :isFullWidth="true" />
        </div>
        <div class="form__field">
          Already registered?<br />
          <ArrowLink @click="openHeaderMenuExpand('login')" text="Log In" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  import Button from '@/components/global/Button';
  import ArrowLink from '@/components/global/ArrowLink';

  export default {
    name: 'Gate',

    components: {
      Button,
      ArrowLink
    },

    computed: {
      ...mapState({
        title: state => state.globalData.article_gate_title,
        content: state => state.globalData.article_gate_content
      })
    },

    methods: {
      ...mapActions(['openRegisterWithNewsletterCheckedExpand', 'openHeaderMenuExpand'])
    }
  }
</script>

<template>
	<div class="regimen-product-wrap">
	  <div class="regimen-product-toggle">
	    <button :class="{'is-active' : !altProd}" @click="altProd = false">Best Match</button>
	    <button v-if="altName" :class="{'is-active' : altProd}" @click="altProd = true">Best Value</button>
	  </div>

	  <div v-if="!altProd" class="regimen-product product">

	    <LazyImage
	      :src="image"
	      :retinaSrc="image2x"
	      :width="imageWidth"
	      :height="imageHeight"
	      alt="Alt text goes here"
	      class="product"
	    />

	    <div class="regimen-product__info">
	      <nuxt-link to="#" class="label">{{brand}}</nuxt-link>
	      <div>
	        <h4><nuxt-link to="#">{{name}}</nuxt-link></h4>
					<Icons :isDay="isDay" :isNight="isNight" />
	        <div class="price">${{price}}<span>${{subPrice}}</span></div>
	      </div>
	    </div>

	    <div class="regimen-product__content wysiwyg">
	      <p>{{description}}</p>
	    </div>

	    <div class="regimen-product__instructions wysiwyg">
	      <p>{{instructions}}</p>
	    </div>

	    <div class="regimen-product__buttons">
	    	<div v-if="hasOptions" class="quantity">
          <label for="skin-tone-1" class="accessible">Select Skin Tone</label>
          <select id="skin-tone-1">
            <option disabled selected>Select Skin Tone</option>
            <option value="1">Options</option>
            <option value="2">Go</option>
            <option value="3">Here</option>
          </select>
        </div>
	      <div>
	        <Button v-if="inRegimen" text="In Your Regimen" size="small" hideArrow isAltBG hasCheck/>
	        <Button v-else text="ADD TO REGIMEN" size="small" hideArrow hasPlus/>
	        <button v-if="inRegimen" class="remove">Remove</button>
	      </div>
	    </div>

	  </div>

	  <div v-if="altProd" class="regimen-product">

	    <LazyImage
	      :src="altImage"
	      :retinaSrc="altImage2x"
	      :width="altImageWidth"
	      :height="altImageHeight"
	      alt="Alt text goes here"
	      class="product alt"
	    />

	    <div class="regimen-product__info">
	      <span class="label">{{altBrand}}</span>
	      <div>
	        <h4><nuxt-link to="#">{{altName}}</nuxt-link></h4>
	        <Icons :isDay="isDay" :isNight="isNight" />
	        <div class="price">${{altPrice}}<span>${{altSubPrice}}</span></div>
	      </div>
	    </div>

	    <div class="regimen-product__content">
	      <p>{{altDescription}}</p>
	    </div>

	    <div class="regimen-product__instructions">
	      <p>{{altInstructions}}</p>
	    </div>

	    <div class="regimen-product__buttons">
	    	<div v-if="altHasOptions" class="quantity">
          <label for="skin-tone-2" class="accessible">Select Skin Tone</label>
          <select id="skin-tone-2">
            <option disabled selected>Select Skin Tone</option>
            <option value="1">Options</option>
            <option value="2">Go</option>
            <option value="3">Here</option>
          </select>
        </div>
	      <div>
	        <Button v-if="altInRegimen" text="In Your Regimen" size="small" hideArrow isAltBG hasCheck/>
	        <Button v-else text="ADD TO REGIMEN" size="small" hideArrow hasPlus/>
	        <button v-if="altInRegimen" class="remove">Remove</button>
	      </div>
	    </div>

	  </div>
	</div>
</template>

<script>
	import LazyImage from '@/components/global/LazyImage';
	import Button from '@/components/global/Button';
	import Icons from '@/components/shop/Icons';

  export default {
    name: 'Product',

    components: {
    	LazyImage,
    	Button,
			Icons
    },

		data() {
    	return {
    		altProd: false
    	}
    },

    props: {
      image: {
        type: String,
        default: ''
      },
      image2x: {
        type: String,
        default: ''
      },
      imageWidth: {
        type: [String, Number],
        default: '100',
      },
      imageHeight: {
        type: [String, Number],
        default: '100',
      },
      brand: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      isDay: {
      	type: Boolean,
      	default: false,
      },
      isNight: {
      	type: Boolean,
      	default: false,
      },
      price: {
        type: String,
        default: ''
      },
      subPrice: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      instructions: {
        type: String,
        default: ''
      },
      inRegimen: {
      	type: Boolean,
      	default: false,
      },
      hasOptions: {
      	type: Boolean,
      	default: false,
      },
      altImage: {
        type: String,
        default: ''
      },
      altImage2x: {
        type: String,
        default: ''
      },
      altImageWidth: {
        type: [String, Number],
        default: '100',
      },
      altImageHeight: {
        type: [String, Number],
        default: '100',
      },
      altBrand: {
        type: String,
        default: ''
      },
      altName: {
        type: String,
        default: ''
      },
      altIsDay: {
      	type: Boolean,
      	default: false,
      },
      altIsNight: {
      	type: Boolean,
      	default: false,
      },
      altPrice: {
        type: String,
        default: ''
      },
      altSubPrice: {
        type: String,
        default: ''
      },
      altDescription: {
        type: String,
        default: ''
      },
      altInstructions: {
        type: String,
        default: ''
      },
      altInRegimen: {
      	type: Boolean,
      	default: false,
      },
      altHasOptions: {
      	type: Boolean,
      	default: false,
      },
    }
  }
</script>

<template>
  <div class="service">
    <div class="row">
      <div class="column small-12 medium-8">
        <h3>{{ title }}</h3>
        <div class="service__info">
          <prismic-rich-text :field="text" />
          <!-- {{ defaultText }}
          <template v-if="moreText && isMoreTextOpen">
            {{ moreText }}
          </template> -->
          <span v-if="time">{{ time }} Minutes</span>
          <!-- <button v-if="moreText && !isMoreTextOpen" class="plain-link" @click="openMoreText">More +</button> -->
        </div>
      </div>
      <div class="column small-12 medium-4">
        <span v-if="computedPrice">{{ computedPrice }}</span>
        <ArrowLink text="Book Now" @click="openBookingModal({ studio: studio, service: title })" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  import ArrowLink from '@/components/global/ArrowLink';

  export default {
    name: 'About',

    components: {
      ArrowLink
    },

    data() {
      return {
        isMoreTextOpen: false
      }
    },

    props: {
      title: {
        type: String,
        default: null
      },
      text: {
        type: Array,
        // type: String,
        default: null
      },
      time: {
        type: String,
        default: null
      },
      price: {
        type: String,
        default: null
      },
      studio: {
        type: Object,
        default: null
      }
    },

    computed: {
      computedPrice() {
        if(this.price) {
          const isFirstCharNumber = ['0','1','2','3','4','5','6','7','8','9'].includes(this.price.charAt(0));

          return isFirstCharNumber ? `$${this.price}` : this.price;
        }
      }
      // defaultText() {
      //   if(this.text) {
      //     return this.text.split(' ').slice(0, 31).join(' ');
      //   }
      // },
      // moreText() {
      //   if(this.text) {
      //     return this.text.split(' ').slice(31, this.text.length).join(' ');
      //   }
      // }
    },

    methods: {
      ...mapActions([
        'openBookingModal'
      ]),
      // openMoreText() {
      //   this.isMoreTextOpen = true;
      // }
    }
  }
</script>
